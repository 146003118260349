import React from "react";
import Bonus from "./Bonus";
import ReferralLink from "./ReferralLink";
import ReferralsInfoRow from "./ReferralsInfoRow";

import { SubmitButton } from "../../components/shared/SubmitButton";
import { useReferrals } from "../../hooks/useReferrals";
import Loading from "../../components/shared/Loading";
import PageStatus from "../../components/PageStatus/PageStatus";
const icons = {
  registererd: (
    <svg
      width="40"
      height="40"
      viewBox="0 0 106 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.83 52.4089L5.55238 52.4046C6.32454 49.4805 8.6282 47.1336 11.6737 46.3699L20.2289 44.2312C21.0055 44.0403 21.6127 43.433 21.808 42.6564L22.693 39.1163C22.8404 38.5219 22.732 37.8886 22.3849 37.3766C22.0422 36.869 21.4956 36.5306 20.8839 36.4438C18.2418 36.0751 16.2202 35.5154 14.8232 35.0294C16.1985 32.6607 17.7212 28.4395 17.7212 21.3809C17.7212 11.2249 25.1311 9.02536 26.9662 9.02536C29.0789 9.02536 29.4433 9.36802 30.2329 10.1056C31.105 10.9256 32.4758 10.8865 33.3002 10.0145C34.1244 9.1425 34.0811 7.76718 33.2091 6.94723C31.4088 5.25528 29.834 4.68262 26.9662 4.68262C22.7103 4.68262 13.3828 8.88647 13.3828 21.3766C13.3828 31.255 10.0987 34.1661 10.103 34.1661C9.52156 34.5999 9.20066 35.2897 9.23971 36.0143C9.27448 36.7344 9.66493 37.3896 10.2852 37.7625C10.4111 37.8364 12.9705 39.3504 17.9163 40.3353L10.6236 42.1616C4.91912 43.589 0.936523 48.6954 0.936523 54.5782C0.936523 55.7755 1.90838 56.7474 3.10576 56.7474H15.8302C17.0276 56.7474 17.9994 55.7755 17.9994 54.5782C17.9994 53.3808 17.0274 52.4089 15.83 52.4089Z"
        className="fill-white light:fill-[--indigo-purple]"
      />
      <path
        d="M95.3692 42.1615L88.0765 40.3394V40.3306C92.9874 39.3457 95.5732 37.8447 95.6946 37.7709C96.2933 37.4153 96.6619 36.7688 96.7228 36.066C96.7791 35.3674 96.4712 34.6603 95.9376 34.2091C95.9028 34.183 92.6057 31.2764 92.6057 21.3805C92.6057 11.6801 90.1329 6.76466 85.248 6.76466H85.083C83.4822 5.30697 82.0678 4.68652 79.0268 4.68652C77.747 4.68652 76.0463 5.13778 74.3587 5.9317C73.2741 6.43928 72.8056 7.73204 73.3132 8.81676C73.825 9.90128 75.1223 10.3613 76.1983 9.85796C77.6082 9.19847 78.6624 9.025 79.0268 9.025C81.2393 9.025 81.5169 9.29832 82.6753 10.4654C83.083 10.8689 83.6341 11.0989 84.211 11.0989H85.248C87.1091 11.0989 88.2675 15.0381 88.2675 21.3765C88.2675 28.4306 89.7859 32.6475 91.1611 35.0162C89.7597 35.5065 87.7294 36.0662 85.1003 36.4349C84.4886 36.5172 83.9463 36.8601 83.6036 37.3677C83.2565 37.8795 83.1481 38.5087 83.2955 39.1074L84.1805 42.6518C84.3758 43.4284 84.9787 44.0401 85.7596 44.2309L94.3148 46.3696C97.3603 47.1332 99.6639 49.4802 100.436 52.4086H90.1672V56.7468L102.887 56.7426C104.085 56.7426 105.056 55.7707 105.056 54.5733C105.057 48.6905 101.074 43.5886 95.3692 42.1615Z"
        className="fill-white light:fill-[--indigo-purple]"
      />
      <path
        d="M75.0831 51.4327L64.6841 48.8339L64.3675 47.5542C71.4564 46.2484 75.1527 44.1009 75.3174 44.0011C75.9249 43.6409 76.3066 42.9902 76.3586 42.2831C76.4107 41.576 76.0983 40.8732 75.5474 40.4262C75.5041 40.3872 70.7231 36.3177 70.7231 22.3311C70.7231 9.62839 67.6125 3.18595 61.4782 3.18595H60.9488C58.9097 1.264 57.1918 0.357422 52.9924 0.357422C47.4392 0.357422 35.2616 5.88881 35.2616 22.3311C35.2616 36.3179 30.4851 40.3872 30.4721 40.4002C29.8952 40.8297 29.5698 41.5237 29.6088 42.244C29.6436 42.9641 30.0341 43.6194 30.6543 43.9923C30.8235 44.0922 34.4807 46.2527 41.6172 47.5584L41.3049 48.8296L30.9059 51.4284C23.4786 53.2853 18.29 59.9315 18.29 67.593C18.29 68.17 18.5156 68.7253 18.9235 69.1288C19.3314 69.5323 19.8823 69.7623 20.4593 69.7623H85.5301C86.7275 69.7623 87.6994 68.7904 87.6994 67.593C87.699 59.9315 82.5105 53.2895 75.0831 51.4327ZM22.8191 65.4195C23.6563 60.7038 27.1791 56.8296 31.9599 55.6365L43.6213 52.7211C44.398 52.5259 45.0052 51.9185 45.2004 51.1421L46.4021 46.3135C46.5495 45.7148 46.4411 45.0813 46.094 44.5737C45.7513 44.0661 45.2047 43.7278 44.593 43.6409C40.3241 43.0465 37.2092 42.0834 35.2742 41.3415C37.1787 38.4479 39.604 32.7213 39.604 22.3223C39.604 7.82784 50.3414 4.68695 52.9964 4.68695C56.2111 4.68695 56.7968 5.14248 58.5408 6.88222C58.9486 7.28569 59.4997 7.51569 60.0766 7.51569H61.4865C64.6058 7.51569 66.3932 12.9127 66.3932 22.3225C66.3932 32.7128 68.8097 38.4351 70.7142 41.3332C68.7662 42.0793 65.6426 43.0424 61.3954 43.6369C60.7837 43.7192 60.2413 44.0621 59.8987 44.5697C59.5515 45.0815 59.4431 45.715 59.5906 46.3094L60.7967 51.1423C60.9919 51.9189 61.5949 52.5306 62.3758 52.7213L74.0373 55.6367C78.8138 56.8298 82.3409 60.7083 83.1738 65.424L22.8191 65.4195Z"
        className="fill-white light:fill-[--indigo-purple]"
      />
    </svg>
  ),
  profit: (
    <svg
      width="40"
      height="40"
      viewBox="0 0 72 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M9.51471 22.2296C12.0461 19.6982 15.6886 18.99 21.7554 18.7918L26.2562 18.7205C27.4305 18.7148 28.6765 18.7148 30 18.7148H42C43.3236 18.7148 44.5695 18.7148 45.7437 18.7205L50.2446 18.7918C56.3115 18.99 59.9538 19.6982 62.4852 22.2296C66 25.7443 66 31.4012 66 42.7148C66 54.0284 66 59.6855 62.4852 63.2C58.9707 66.7148 53.3136 66.7148 42 66.7148H29.9999C18.6863 66.7148 13.0294 66.7148 9.51471 63.2C6 59.6855 6 54.0284 6 42.7148C6 31.4012 6 25.7443 9.51471 22.2296Z"
        className="fill-white light:fill-[--indigo-purple]"
      />
      <path
        d="M38.25 30.7148C38.25 29.4722 37.2426 28.4648 36 28.4648C34.7574 28.4648 33.75 29.4722 33.75 30.7148V30.7454C30.4836 31.5683 27.75 34.1438 27.75 37.7147C27.75 42.0857 31.8453 44.9648 36 44.9648C38.4726 44.9648 39.75 46.5626 39.75 47.7149C39.75 48.8669 38.4726 50.4647 36 50.4647C33.5274 50.4647 32.25 48.8669 32.25 47.7149C32.25 46.4723 31.2426 45.4649 30 45.4649C28.7574 45.4649 27.75 46.4723 27.75 47.7149C27.75 51.2858 30.4836 53.8613 33.75 54.6842V54.7148C33.75 55.9574 34.7574 56.9648 36 56.9648C37.2426 56.9648 38.25 55.9574 38.25 54.7148V54.6842C41.5164 53.8613 44.25 51.2858 44.25 47.7149C44.25 43.3439 40.1547 40.4648 36 40.4648C33.5274 40.4648 32.25 38.867 32.25 37.7147C32.25 36.5627 33.5274 34.9649 36 34.9649C38.4726 34.9649 39.75 36.5627 39.75 37.7147C39.75 38.9573 40.7574 39.9647 42 39.9647C43.2426 39.9647 44.25 38.9573 44.25 37.7147C44.25 34.1438 41.5164 31.5683 38.25 30.7454V30.7148Z"
        className="fill-white light:fill-[--indigo-purple]"
      />
      <path
        d="M36.1566 4.46485H35.8446C33.1491 4.46476 30.9015 4.4647 29.117 4.70458C27.2337 4.95781 25.5333 5.51482 24.1669 6.88123C22.8005 8.24764 22.2435 9.94804 21.9902 11.8314C21.8183 13.1105 21.7696 16.1693 21.7559 18.7918L26.2567 18.7205C26.2703 16.1805 26.3135 13.4471 26.4501 12.431C26.6362 11.0466 26.9579 10.4542 27.3488 10.0632C27.7398 9.67225 28.3323 9.35059 29.7167 9.16447C31.1658 8.96965 33.1086 8.96485 36.0006 8.96485C38.8926 8.96485 40.8351 8.96965 42.2844 9.16447C43.6686 9.35059 44.2611 9.67225 44.652 10.0632C45.0432 10.4542 45.3648 11.0466 45.5508 12.431C45.6876 13.4471 45.7308 16.1805 45.7443 18.7205L50.2452 18.7918C50.2314 16.1693 50.1828 13.1105 50.0106 11.8314C49.7574 9.94804 49.2006 8.24764 47.8341 6.88123C46.4676 5.51482 44.7672 4.95781 42.8838 4.70458C41.0997 4.4647 38.8518 4.46476 36.1566 4.46485Z"
        className="fill-white light:fill-[--indigo-purple]"
      />
    </svg>
  ),
};

export default function Referrals() {
  const { loading, user, referralBalances, handleBalance } = useReferrals();

  return (
    <>
      <PageStatus
        category={"account"}
        currentPage={"referrals"}
        logoStarColor="#F8CC15"
      />
      <div className="mx-auto max-w-4xl">
        <Bonus
          referralQrCode={user.referral_qrcode_img}
          token={
            process.env.REACT_APP_FRONT_URL +
            "/signup?ref=" +
            user.referral_token
          }
        />
        <ReferralLink
          token={
            process.env.REACT_APP_FRONT_URL +
            "/signup?ref=" +
            user.referral_token
          }
        />
        <ReferralsInfoRow
          icon={icons.registererd}
          text={"The number of people who registered with your code"}
          value={`${user.referral_count} Account`}
        />
        <ReferralsInfoRow
          icon={icons.registererd}
          text={"The number of challenges purchased by invited users"}
          value={`${user.referral_challenge_count} Challenges`}
        />
        {loading ? (
          <div className="h-[228px] flex items-center justify-center">
            <Loading optClass={"block mx-auto"} />
          </div>
        ) : (
          <>
            <ReferralsInfoRow
              icon={icons.profit}
              text={"Total referral fund profit"}
              value={`$ ${Intl.NumberFormat().format(
                referralBalances.fundShareSum
              )}`}
            />
            <ReferralsInfoRow
              icon={icons.profit}
              text={"Total referral profit share"}
              value={`$ ${Intl.NumberFormat().format(
                referralBalances.profitShareSum
              )}`}
            />

            <ReferralsInfoRow
              icon={icons.profit}
              text={"Your profit balance in referral wallet"}
              value={`$ ${Intl.NumberFormat().format(
                referralBalances.balance
              )}`}
            />
          </>
        )}
        <div className="w-fit mx-auto">
          <SubmitButton
            onClick={handleBalance}
            text="Request transfer to wallet"
            optClass={"!max-w-sm mx-auto "}
          />
        </div>
      </div>
    </>
  );
}
