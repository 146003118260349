import { useState, useEffect } from "react";

const useThemeToggle = () => {
  const isThemeLight = localStorage.getItem("theme") === "light";
  const [themeToggle, setThemeToggle] = useState(isThemeLight);

  useEffect(() => {
    if (themeToggle) {
      document.body.classList.add("light");
    } else {
      document.body.classList.remove("light");
    }
  }, [themeToggle]);

  const handleThemeToggle = () => {
    if (themeToggle) {
      localStorage.removeItem("theme");
    } else {
      localStorage.setItem("theme", "light");
    }
    setThemeToggle(!themeToggle);
  };

  return { themeToggle, handleThemeToggle };
};

export default useThemeToggle;
