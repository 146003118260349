import React, { useState } from "react";
import PageStatus from "../../components/PageStatus/PageStatus";
import { ChallengeType } from "./ChallengeType";
import { PlanInfo } from "./PlanInfo";
import { useSelector } from "react-redux";
import Loading from "../../components/shared/Loading";
import StepindicatorContainer from "../../components/indicator/StepIndicatorContainer";
import StepIndicator from "../../components/indicator/StepIndicator";
import ConfirmPay from "./ConfirmPay";

const tempData = {
  step: 1,
};
const stepIndicatorHeader = [
  "Select your plan",
  "Choose plan details",
  "Pay and show your best",
];

export default function NewChallenge() {
  const [data, setData] = useState(tempData);
  const [payData, setPayData] = useState(null);
  const { challenges } = useSelector((state) => state.challenge);

  return (
    <div className="relative">
      <div className="block pb-5">
        <PageStatus
          category={"challenges"}
          currentPage={"New Challenge"}
          pageStarColor={"#11092E"}
          logoStarColor="#ebc216"
        />
        <StepindicatorContainer
          headerText={
            stepIndicatorHeader[data.step - 1] ?? stepIndicatorHeader[0]
          }
          optClass="max-w-xl"
        >
          <StepIndicator
            num={1}
            text={"select challenge type"}
            currentStep={data.step}
            onClick={setData}
          />
          <StepIndicator
            num={2}
            text={"Plan details"}
            currentStep={data.step}
          />
          <StepIndicator
            num={3}
            text={"pay"}
            last={true}
            currentStep={data.step}
          />
        </StepindicatorContainer>
        {challenges[0] ? (
          <>
            {data.step === 1 && (
              <ChallengeType setData={setData} challenges={challenges} />
            )}
            {data.step === 2 && (
              <PlanInfo
                setData={setData}
                data={data}
                challenges={challenges}
                setPayData={setPayData}
              />
            )}
            {data.step === 3 && <ConfirmPay data={payData} setStep={setData} />}
          </>
        ) : (
          <div className="w-full text-center">
            <Loading />
          </div>
        )}
      </div>
    </div>
  );
}
