export function VerificationSuccess() {
  return (
    <div className="max-w-sm flex flex-col items-center">
      <p className="text-2xl text-center font-bold text-green-400 light:text-emerald-600">
        Your verification has been successfully completed
      </p>
      <img src="../Illustration.png" className="w-10/12 my-10" alt="" />
      {/* <SubmitButton
        text="go to my wallets"
        onClick={() => navigate("/dashboard/wallet")}
      />
      <SubmitButton
        bgColor="bg-orange-300"
        textColor="primaryPurple"
        text="Back"
      /> */}
    </div>
  );
}
