import React from "react";
import Table from "../../../components/AdminShared/Table";
import ErrorMessage from "../../../components/shared/ErrorMessage";

const BannerIndex = ({ banners, setType, deleteBanners }) => {
  console.log(banners);
  const url = process.env.REACT_APP_API_BASE_URL + "/storage/";
  return (
    <div>
      <div className=" flex items-center justify-end text-center text-white mb-5">
        <button
          onClick={() => setType("create")}
          className="btn btn-outline btn-success "
        >
          create
        </button>
      </div>
      {banners.length === 0 ? (
        <ErrorMessage />
      ) : (
        <Table theads={["Id", "Title", "Photo", "Actions"]}>
          {banners.map((banner) => (
            <tr>
              <td>{banner.id}</td>
              <td>
                <p>{banner.title}</p>
              </td>
              <td>
                <div className="max-w-44 sm:max-w-80">
                  <img src={url + banner.photo} alt={banner.title} />
                </div>
              </td>
              <th>
                <button
                  onClick={() => deleteBanners(banner.id)}
                  className="btn btn-sm btn-outline btn-error"
                >
                  delete
                </button>
              </th>
            </tr>
          ))}
        </Table>
      )}
    </div>
  );
};

export default BannerIndex;
