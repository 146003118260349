import React from "react";

export function Input({
  label,
  name,
  value,
  type = "text",
  onChange,
  disabled,
}) {
  return (
    <div
      className={`relative w-full rounded-xl border-y-2   ${
        disabled
          ? "bg-slate-700 light:bg-gray-300 light:border-gray-400"
          : "  bg-neutral-900 light:bg-neutral-200 border-[--primary-text] light:shadow-[0_0_8px_-2px] light:shadow-neutral-600"
      }`}
    >
      <div className="text-xs text-neutral-400 light:text-black bg-neutral-700 light:bg-neutral-300 rounded-xl  leading-none px-3 pt-1 absolute -top-2 left-5 ">
        {label}
      </div>
      <input
        type={type}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className="text-white bg-transparent border-0 outline-none px-5 py-3"
      />
    </div>
  );
}
