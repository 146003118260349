import React from "react";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

export function ChatTitle({ title, id }) {
  const navigate = useNavigate();
  return (
    <div className="w-full flex items-center justify-between mb-5">
      <div className="flex items-center gap-2">
        <p className="text-[--primary-text] text-2xl font-semibold -mb-2">
          {title}
        </p>
      </div>
      <div className="flex items-center gap-2">
        <button
          onClick={() => navigate(-1)}
          className="btn btn-sm btn-square gap-0 px-1 pr-1.5 pb-1 justify-start w-fit bg-[var(--indigo-purple)] hover:bg-[var(--indigo-purple-hover)] text-white border-[--border-light] hover:border-indigo-500 light:hover:border-orange-500"
        >
          <IoArrowBack size={19} />
          <span className="-mb-1.5"> Back</span>
        </button>
      </div>
    </div>
  );
}
