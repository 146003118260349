import React from "react";
import RequestDetailsInput from "../../AdminShared/RequestsDetailsInput";
import RequestDetailsButton, {
  RequestDetailsButtonLayout,
} from "../../AdminShared/RequestsDetailsButton";

const MyWalletFilter = ({
  data,
  handleSubmitFilter,
  handleFilterChange,
  filter,
}) => {
  const {
    wallet_type_names: walletTypes,
    transition_type_names: transitionTypes,
  } = data;

  return (
    <div className="w-full bg-transparent p-5 rounded-xl mt-16">
      <form onSubmit={handleSubmitFilter}>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-x-3">
          <label htmlFor="status" className="form-control">
            <div className="label">
              <span
                className={`label-text text-slate-100 light:text-[--primary-text]`}
              >
                Status:
              </span>
            </div>
            <select
              id="status"
              name="status"
              value={filter.status}
              onChange={handleFilterChange}
              className="select select-sm  text-black select-bordered bg-slate-300  light:bg-slate-100"
            >
              <option value="">All</option>

              <option value="success">Success</option>
              <option value="failed">Failed</option>
              <option value="pending">Pending</option>
            </select>
          </label>
          <label htmlFor="walletType" className="form-control">
            <div className="label">
              <span
                className={`label-text text-slate-100 light:text-[--primary-text]`}
              >
                Wallet Name:
              </span>
            </div>
            <select
              id="walletType"
              name="wallettypeid"
              value={filter.wallettypeid}
              onChange={handleFilterChange}
              className="select select-sm  text-black select-bordered bg-slate-300 light:bg-slate-100 "
            >
              <option value="">All</option>
              {Object.entries(walletTypes).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
          </label>
          <label htmlFor="transitionType" className="form-control">
            <div className="label">
              <span
                className={`label-text text-slate-100 light:text-[--primary-text]`}
              >
                Type:
              </span>
            </div>
            <select
              id="transitionType"
              name="transitiontypeid"
              value={filter.transitiontypeid}
              onChange={handleFilterChange}
              className="select select-sm  text-black select-bordered bg-slate-300 light:bg-slate-100 "
            >
              <option value="">All</option>
              {Object.entries(transitionTypes).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
          </label>

          <RequestDetailsInput
            title="Date From"
            name="fromDate"
            onChange={handleFilterChange}
            value={filter.fromDate}
            type="date"
          />
          <RequestDetailsInput
            title="Date To"
            name="toDate"
            onChange={handleFilterChange}
            value={filter.toDate}
            type="date"
          />
        </div>
        <RequestDetailsButtonLayout optClass="mt-4 mb-0 justify-end">
          <RequestDetailsButton
            optClass="btn-error "
            title="Reset"
            onclick={() => handleFilterChange("reset")}
          />
          <RequestDetailsButton optClass="btn-success " title="Filter" />
        </RequestDetailsButtonLayout>
      </form>
    </div>
  );
};

export default MyWalletFilter;
