import React from "react";
import { ChatTitle } from "../../Tickets/Chat/ChatTitle";
import { ChatForm } from "./ChatForm";
import { Chat } from "./Chat";
import { useParams } from "react-router-dom";
import Loading from "../../../components/shared/Loading";
import { useTicketChat } from "../../../hooks/useTicketChat";

export default function AdminChatLayout() {
  const { id } = useParams();
  const {
    loading,
    error,
    title,
    user,
    status,
    messages,
    newMessage,
    pushMess,
    pushFile,
    setNewMessage,
    handleClose,
  } = useTicketChat(id, "admin");

  return (
    <div className="h-full bg-[var(--indigo-purple-dark)]  rounded-xl flex flex-col items-start justify-between mx-auto p-5 mt-6 text-neutral-100">
      {error ? (
        "error fetching data"
      ) : loading || title === "" ? (
        <div className="w-full text-center">
          <Loading />
        </div>
      ) : (
        <>
          <ChatTitle title={title} id={id} />
          <Chat messages={messages} newMessage={newMessage} user={user} />
          {status === "closed" ? (
            <div className="w-full px-3 py-5 font-semibold text-error">
              This ticket is closed!
            </div>
          ) : (
            <ChatForm
              addNewMessage={pushMess}
              addNewFile={pushFile}
              setNewMessage={setNewMessage}
              handleClose={handleClose}
            />
          )}
        </>
      )}
    </div>
  );
}
