import { InitialInfoForm } from "./InitialInfoForm";
import { PaymentLayout } from "./PaymentLayout";
import { TransferDetailsLayout } from "../../components/shared/TransferDetailsLayout";
import { TransferDetail } from "../../components/shared/TransferDetail";
import { TransactionResult } from "../../components/shared/TransactionResult";
import { SubmitButton } from "../../components/shared/SubmitButton";
import PageStatus from "../../components/PageStatus/PageStatus";
import { useWithdraw } from "../../hooks/useWithdraw";
import Loading from "../../components/shared/Loading";
import { useNavigate } from "react-router-dom";
import { formatComma } from "../../utils/formatNumber";
import StepindicatorContainer from "../../components/indicator/StepIndicatorContainer";
import StepIndicator from "../../components/indicator/StepIndicator";

const stepIndicatorHeader = [
  "Enter your withdraw amount",
  "Enter your wallet address (TRX-TRC20)",
  "Review the transaction details",
  "Withdraw State",
];

export default function Withdraw() {
  const {
    loading,
    withdrawData,
    handleSubmitWithdraw,
    handleSetWithdraw,
    handleResetSetWithdraw,
  } = useWithdraw();
  const navigate = useNavigate();

  return (
    <>
      <PageStatus
        category={"finance"}
        currentPage={"withdraw "}
        logoStarColor="#F8CC15"
      />
      <StepindicatorContainer
        headerText={
          stepIndicatorHeader[withdrawData.step - 1] ?? stepIndicatorHeader[0]
        }
        optClass="max-w-2xl"
      >
        <StepIndicator
          num={1}
          text={"Withdraw Amount"}
          currentStep={withdrawData.step}
          onClick={handleSetWithdraw}
        />
        <StepIndicator
          num={2}
          text={"Wallet Address"}
          currentStep={withdrawData.step}
          onClick={handleSetWithdraw}
        />
        <StepIndicator
          num={3}
          text={"Confirmation"}
          currentStep={withdrawData.step}
          onClick={handleSetWithdraw}
        />
        <StepIndicator
          num={4}
          text={"Result"}
          last
          currentStep={withdrawData.step}
          onClick={handleSetWithdraw}
        />
      </StepindicatorContainer>

      {withdrawData.step === 1 && (
        <InitialInfoForm handleSetWithdraw={handleSetWithdraw} />
      )}

      {withdrawData.step === 2 && (
        <PaymentLayout
          handleSetWithdraw={handleSetWithdraw}
          withdrawData={withdrawData}
        />
      )}

      {withdrawData.step === 3 &&
        (loading ? (
          <div className="w-full h-48 flex items-center justify-center">
            <Loading />
          </div>
        ) : (
          <div className="flex flex-col lg:flex-row items-center gap-10 ">
            <TransferDetailsLayout>
              <TransferDetail
                title={"Withdraw with"}
                value={withdrawData.type}
              />
              <TransferDetail
                title={"withdraw to"}
                value={withdrawData.trc20_wallet_address}
              />
              <TransferDetail
                title={"withdraw requested amount"}
                value={`$${formatComma(withdrawData.amount)}`}
              />
              <TransferDetail
                title={"withdraw amount after fee"}
                value={`$${formatComma(withdrawData.amount - 1.5)}`}
              />
            </TransferDetailsLayout>
            <div>
              <SubmitButton onClick={handleSubmitWithdraw} />
              <SubmitButton
                onClick={() => handleResetSetWithdraw()}
                text="back"
                optClass="border border-[var(--indigo-purple)] bg-[var(--indigo-purple-dark)] hover:border-[--border-light] hover:bg-[var(--indigo-purple-dark)]"
              />
            </div>
          </div>
        ))}

      {withdrawData.step === 4 && (
        <TransactionResult
          imgUrl={"../Group 115.png"}
          headerText={"Your request has been successfully submitted!"}
          firstBtnText="go to my wallets"
          firstBtnClick={() => navigate("/dashboard/wallet")}
          secBtnText="back"
          secBtnClick={() => handleResetSetWithdraw()}
        />
      )}
    </>
  );
}
