import React from "react";
import Numbers from "./CircleAnimation/Numbers";
import Layout from "./CircleAnimation/Layout";
import Inner from "./CircleAnimation/Inner";
import Middle from "./CircleAnimation/Middle";
import Lines from "./CircleAnimation/Lines";

const CircleAnime = ({ userRole }) => {
  return (
    <div
      className={`anime-circle ${
        userRole === "trader" &&
        "before:bg-[#2807ff60] light:before:bg-[#2376f321]"
      }  ${
        userRole === "investor" &&
        "before:bg-[#f8cb1550] light:before:bg-[#f87b151f]"
      } before:transition-all !h-screen !top-0 lg:!relative before:mix-blend-exclusion light:before:mix-blend-normal `}
    >
      <div
        className={`container-circle ${
          userRole === "investor" && "sepia"
        } light:contrast-200 `}
      >
        <div
          style={{ right: 0 }}
          className="circle-iner lg:left-[50%] lg:!top-[50%] lg:translate-x-[-50%] lg:translate-y-[-50%] light:brightness-[0.6]"
        >
          <div className="cicle-rel">
            <Numbers />
            <Layout />
            <Inner />
            <Middle />
            <Lines />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CircleAnime;
