import React from "react";
import { ProfilePhoto } from "./ProfilePhoto";
import UserRankTable from "./UserRankTable";

export function UserInfo({ user, userRank }) {
  return (
    <>
      <div className="flex items-center gap-2">
        <span className="badge badge-xs bg-white border-transparent "></span>
        <span className="text-white text-2xl font-semibold ">You</span>
      </div>
      <div className=" grid grid-cols-1 items-center justify-items-center relative z-40">
        <ProfilePhoto user={user} />
        <div className="w-fit mt-5">
          {userRank.rank && (
            <p className="pr-7 lg:pr-0 relative text-white text-2xl font-bold text-end">
              {userRank.rank.rank}
              <span className="text-sm absolute top-0 right-2 lg:-right-5 !normal-case">
                th
              </span>
            </p>
          )}
          <p className="text-white text-5xl font-bold my-4">
            {user.first_name + " " + user.last_name}
          </p>
        </div>
        {userRank.data?.rank && <UserRankTable userRank={userRank.data.rank} />}
        {userRank.message && (
          <p className="bg-[var(--indigo-purple-light)] border border-light-border text-[--primary-text] rounded-lg text-lg text-center font-bold px-2 py-5 w-full">
            {userRank.message}
          </p>
        )}
      </div>
    </>
  );
}
