import React from "react";
import { TableHeaders } from "./TableHeaders";
import { ActivePlansRow } from "./ActivePlansRow";
import { RowTitles } from "./RowTitles";
import {
  CollapseContent,
  CollapseContentRow,
  CollapseContentRowInput,
} from "./CollapseContentRow";
import ErrorMessage from "../../components/shared/ErrorMessage";

const activeHeaders = [
  "phase",
  "balance",
  "challenge",
  "scaled up",
  "start time",
  "end time",
];
export function ActivePlans({ plans }) {
  return (
    <>
      <div className="w-full rounded-full bg-[var(--indigo-purple-hover)] py-2 border border-[--border-light] text-white  text-center text-xl font-semibold mt-20 mb-5 capitalize">
        active plans
      </div>
      <div className="overflow-hidden overflow-x-auto">
        {(!plans || plans.length === 0) && (
          <ErrorMessage
            text="You don't have an active plan! "
            optcClass="justify-center mt-2"
          />
        )}
        {plans?.length > 0 && (
          <>
            <TableHeaders headers={activeHeaders} active />

            <div className="min-w-[1000px] [&>*:not(:last-child)]:border-b [&>*:not(:last-child)]:border-[var(--indigo-purple-light)] [&>:last-child]:rounded-b-xl rounded-xl bg-[var(--indigo-purple-dark)] [&>*]:transition-all flex flex-col">
              {plans.map((row) => (
                <ActivePlansRow plans={<RowTitles data={row} />}>
                  <CollapseContent title={"challenge details"}>
                    <CollapseContentRow
                      title={"requested balance"}
                      value={row.balance}
                      balance
                    />
                    <CollapseContentRow
                      title={"start time"}
                      value={row.start_time}
                    />
                    <CollapseContentRow
                      title={"end time"}
                      value={row.end_time}
                    />
                  </CollapseContent>
                  <CollapseContent title={"account info"}>
                    <CollapseContentRow title={"ID"} value={row.login} />
                    <CollapseContentRowInput
                      title={"account password"}
                      value={row.password}
                    />
                    <CollapseContentRowInput
                      title={"investor password"}
                      value={row.investor_password}
                    />
                  </CollapseContent>
                  <CollapseContent title={"broker info"}>
                    <CollapseContentRow
                      title={"broker name"}
                      value={row.broker}
                    />
                    <CollapseContentRow
                      title={"server name"}
                      value={row.server}
                    />
                    <CollapseContentRow
                      title={"platform"}
                      value={row.platform}
                    />
                  </CollapseContent>
                </ActivePlansRow>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
}
