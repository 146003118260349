import React from "react";

export function CertificateLayout({ imgUrl, text, type }) {
  return (
    <div className=" bg-primaryPurple light:bg-neutral-100 rounded-[2rem] pb-3">
      {type === 0 ? (
        <object
          data={imgUrl}
          type="application/pdf"
          className="w-full h-[22.6rem]"
          alt=""
        >
          {" "}
        </object>
      ) : (
        <object
          data={imgUrl}
          type="application/pdf"
          className="w-full h-[39.1rem]"
          alt=""
        >
          {" "}
        </object>
      )}

      {/* <img src={imgUrl} className="w-full h-80" alt="" /> */}
      <button className="w-full flex items-center justify-between px-5 py-3">
        <span className="text-white text-lg text-center font-bold pt-1 capitalize">
          {text}
        </span>
        {/* <RiDownloadLine size={20} fill="#fff" /> */}
      </button>
    </div>
  );
}
