import { SubmitButton } from "../../components/shared/SubmitButton";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFetch } from "../../hooks/API/useFetch";
import { AmountActionButton } from "../MyWallets/AmountActionButton";
import { PiWalletBold } from "react-icons/pi";

export function InitialInfoForm({ handleSetWithdraw }) {
  const [balance, setBalance] = useState(null);
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [error, setError] = useState(false);
  const { loading, fetchData } = useFetch();

  function handleSubmit(e) {
    e.preventDefault();

    if (withdrawAmount < 12) {
      setError("Minimum withdraw is $12");
      toast.error("Minimum withdraw is $12");
      return;
    }
    if (withdrawAmount > +balance) {
      setError("Insufficient Funds!");
      toast.error("Insufficient Funds!");
      return;
    }
    handleSetWithdraw({
      amount: withdrawAmount,
      step: 2,
    });
  }

  const getBalance = async () => {
    const response = await fetchData("finance/wallets/balance");
    if (!response) return;

    setBalance(response.balance);
  };

  useEffect(() => {
    if (balance === null) {
      getBalance();
    }
  }, [balance]);

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="w-full grid grid-cols-1 gap-y-2  relative my-6"
      >
        <div className="flex w-full max-w-xs h-[108px]">
          <AmountActionButton
            loading={loading}
            text="Account Balance"
            value={balance}
            icon={<PiWalletBold color="var(--primary-text)" size={40} />}
          />
        </div>
        <label htmlFor="amount" className="mt-3 text-white">
          Amount:
        </label>
        <div className="w-full flex items-center justify-start">
          <input
            id="amount"
            value={withdrawAmount}
            required={true}
            autoFocus
            onInvalid={(e) => {
              e.preventDefault();
              setError("Please fill the input");
            }}
            placeholder="Please Enter Withdraw Amount Here..."
            onChange={(e) => {
              setWithdrawAmount(e.target.value);
              setError(false);
            }}
            type="number"
            className="w-full max-w-xs bg-transparent border-2 placeholder:text-sm placeholder:opacity-50 text-white text-lg font-normal border-[--primary-text] rounded-lg p-2  focus:outline-0 [&>*]:bg-gray-700 placeholder:text-white "
          />
        </div>
        <span
          className={`text-sm text-rose-600 mb-1 ${error ? "block" : "hidden"}`}
        >
          {error}
        </span>
        <SubmitButton
          optClass="mt-5 disabled:cursor-not-allowed disabled:opacity-40 disabled:hover:bg-[var(--indigo-purple)]"
          disabled={loading}
        />
      </form>
    </>
  );
}
