import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { InputsContainer } from "../shared/InputsContainer";
import Loading from "../../../components/shared/Loading";
import { LoginIcons } from "../shared/Icons";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import CircleAnime from "../../../components/CircleAnime";
import { useLogin } from "../../../hooks/useLogin";
import { Link } from "react-router-dom";
import AuthInput from "../../../components/Auth/AuthInput";
import { AuthSubmitBtn } from "../../../components/Auth/AuthSubmitBtn";
// import Cookie from "js-cookie";

export default function Login() {
  const {
    login,
    loading,
    handleSubmit,
    showPass,
    loginInfo,
    handleSetLoginInfo,
    handleSetShowPass,
  } = useLogin();

  return (
    <div className="w-full h-screen flex md:block lg:flex items-center justify-center flex-row-reverse">
      <CircleAnime userRole={loginInfo.role} />

      <InputsContainer logoColor={loginInfo.role}>
        <p className="text-white text-2xl text-center mt-5">Sign in</p>
        {loading ? (
          <Loading optClass={"block mx-auto mt-20 mb-32"} />
        ) : (
          <form
            onInvalid={(e) => {
              e.preventDefault();
            }}
            onSubmit={handleSubmit}
            className="w-full join join-vertical [&_*]:transition-all"
          >
            <div className="mb-4">
              <div className="w-full grid grid-cols-2 gap-2 my-5">
                <input
                  type="button"
                  onClick={(e) => handleSetLoginInfo(e)}
                  className={`btn text-[#fff] border-0  outline-0 hover:bg-indigo-700 bg-[#4743e0] capitalize ${
                    loginInfo.role === "trader" ? "opacity-100" : "opacity-50"
                  } `}
                  value="trader"
                  name="role"
                />

                <input
                  type="button"
                  onClick={(e) => handleSetLoginInfo(e)}
                  className={`btn text-black border-0 outline-0 hover:bg-[var(--invest-gold-hover)] bg-[var(--invest-gold)] capitalize ${
                    loginInfo.role === "investor" ? "opacity-100" : "opacity-50"
                  } `}
                  value="investor"
                  name="role"
                />
              </div>
              <AuthInput
                borderColor={loginInfo.role}
                label="email"
                value={loginInfo.email}
                onChange={(e) => handleSetLoginInfo(e)}
                name="email"
              />
            </div>
            <div className="mb-5">
              <AuthInput
                label="password"
                borderColor={loginInfo.role}
                value={loginInfo.password}
                onChange={(e) => handleSetLoginInfo(e)}
                type={showPass ? "text" : "password"}
                name="password"
              >
                <label className="flex items-center">
                  <input type="checkbox" className="appearance-none" />
                  <FaEye
                    size={20}
                    className={showPass ? "swap-on cursor-pointer" : "hidden"}
                    onClick={handleSetShowPass}
                  />
                  <FaEyeSlash
                    size={20}
                    className={!showPass ? "swap-off cursor-pointer" : "hidden"}
                    onClick={handleSetShowPass}
                  />
                </label>
              </AuthInput>
            </div>
            <AuthSubmitBtn
              title="Sign in"
              type="submit"
              bgColor={loginInfo.role}
            />
            <div className="flex items-center justify-between pt-3">
              <div className="flex items-center gap-1">
                <input
                  id="rememberMe"
                  checked={loginInfo.remember}
                  onChange={(e) => handleSetLoginInfo(e)}
                  name="remember"
                  type="checkbox"
                  className={`checkbox after:!hidden ${
                    loginInfo.role === "trader"
                      ? "[--chkbg:#4743e0]  checked:border-[#4743e0] "
                      : "[--chkbg:var(--invest-gold)] [--chkfg:theme(colors.slate.800)] checked:border-[var(--invest-gold)]"
                  }  rounded-sm w-4 h-4  border-[--primary-text]`}
                />
                <label
                  htmlFor="rememberMe"
                  className=" label label-text text-white  cursor-pointer "
                >
                  Remember me
                </label>
              </div>
              <Link to={"forgot-password"}>
                <p
                  className={`label-text ${
                    loginInfo.role === "trader"
                      ? " hover:text-[#4743e0]"
                      : "hover:text-[var(--invest-gold)] light:hover:text-neutral-500"
                  } text-white text-center `}
                >
                  Forgot your password?
                </p>
              </Link>
            </div>
          </form>
        )}
        <hr className="border-dashed border-[--primary-text] border-t-2 opacity-50 my-3" />
        <div className="text-center  mt-5">
          <p className="text-white">Sign in using</p>
          <div className="flex items-center justify-center gap-3 my-2">
            <button
              onClick={login}
              className="w-12 h-12 flex items-center justify-center rounded-md bg-white"
            >
              {LoginIcons.google}
            </button>
            <button
              onClick={login}
              className="w-12 h-12 flex items-center justify-center rounded-md bg-[#303030]"
            >
              {LoginIcons.microsoft}
            </button>
            <button
              onClick={login}
              className="w-12 h-12 flex items-center justify-center rounded-md bg-black"
            >
              {LoginIcons.apple}
            </button>
          </div>
          <p className=" mt-5 text-[#4099ff]">Don't have an account yet?</p>
          <Link to="signup">
            <p className="text-sm text-white">Register here</p>
          </Link>
        </div>
      </InputsContainer>

      {/* <div className="w-full relative">
        <img
          src="../loginGolden.png "
          className="w-full h-full"
          style={{ transform: "scale(105%)" }}
          alt=""
        />
      </div> */}
    </div>
  );
}
