import React, { useEffect, useState } from "react";
import { RankingTopUsers } from "./RankingTopUsers";
import { useSelector, useDispatch } from "react-redux";
import {
  getGoldenPlanTopTrader,
  getMaximumWithdraw,
  getToptraders,
} from "../../store/slices/topTrader";
import Loading from "../../components/shared/Loading";

const RankingTables = ({ titleBubble, revenueIcon }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const topTrader = useSelector((state) => state.topTrader);
  const topTraders = topTrader.topTraders;
  const maximumWithdraws = topTrader.maximumWithdraws;
  const goldenPlanTopTrader = topTrader.goldenPlanTopTrader;

  useEffect(() => {
    setLoading((x) => true);
    if (
      topTraders.length > 0 &&
      maximumWithdraws.length > 0 &&
      goldenPlanTopTrader.length > 0
    ) {
      setLoading((x) => false);
    }
  }, [topTraders, maximumWithdraws, goldenPlanTopTrader]);

  useEffect(() => {
    if (topTraders.length === 0) {
      dispatch(getToptraders());
    }
  }, [dispatch, topTraders]);

  useEffect(() => {
    if (maximumWithdraws.length === 0) {
      dispatch(getMaximumWithdraw());
    }
  }, [dispatch, maximumWithdraws]);

  useEffect(() => {
    if (goldenPlanTopTrader.length === 0) {
      dispatch(getGoldenPlanTopTrader());
    }
  }, [dispatch, goldenPlanTopTrader]);

  return (
    <>
      {loading ? (
        <Loading optClass={"block mx-auto mt-20"} />
      ) : (
        <>
          <div className="flex items-center justify-between gap-x-3 mt-16 mb-2">
            <div className="flex items-center gap-3 ">
              {titleBubble ? (
                <div className="badge badge-xs mb-2 bg-white border-transparent"></div>
              ) : (
                <img src="../yellowStar.svg" className="w-10 mb-2" alt="" />
              )}
              <span className="text-white text-lg md:text-4xl font-bold capitalize ">
                golden fund family
              </span>
            </div>
          </div>
          <div className="relative z-10">
            <div className="grid grid-cols-1 min-[1100px]:grid-cols-3 gap-x-5 xl:gap-x-24 gap-y-5 mb-20">
              <RankingTopUsers
                headerText={"top Traders"}
                data={topTraders}
                type={"top"}
              />
              <RankingTopUsers
                headerText={"Max Withdraws"}
                data={maximumWithdraws}
                type={"withdraw"}
              />
              <RankingTopUsers
                headerText={"Golden Plan Top Traders"}
                data={goldenPlanTopTrader}
                type={"golden"}
              />
            </div>
            <div className="absolute bottom-[-75%] z-[-1]">
              <img src="../dashboardBgWave.png" alt="" />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default RankingTables;
