import React from "react";
import Table from "../../../components/AdminShared/Table";
const PlanIndex = ({ plans, setType, setFormData }) => {
  const handleUpdate = (plan) => {
    setFormData({
      id: plan.id,
      month: plan.month,
      fund: plan.fund,
      total_fund_under_managment: plan.total_fund_under_managment,
      avg_total_profit: plan.avg_total_profit,
    });
    setType("update");
  };

  return (
    <div>
      <Table
        theads={[
          "Id",
          "Month",
          "Fund",
          "Total fund under management",
          "Avg total profit",
          "Actions",
        ]}
      >
        {plans
          .sort((a, b) => a.month - b.month)
          .map((plan) => (
            <tr>
              <td>{plan.id}</td>
              <td>
                <p>{plan.month}</p>
              </td>
              <td>
                <p>{plan.fund}</p>
              </td>
              <td>
                <p>{plan.total_fund_under_managment}</p>
              </td>
              <td>
                <p>{plan.avg_total_profit}</p>
              </td>
              <th>
                <button
                  onClick={() => handleUpdate(plan)}
                  className="btn btn-sm btn-outline btn-primary"
                >
                  update
                </button>
              </th>
            </tr>
          ))}
      </Table>
    </div>
  );
};

export default PlanIndex;
