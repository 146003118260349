import useThemeToggle from "../hooks/useThemeToggle";
const ThemeToggleBtn = ({ optClass }) => {
  const { themeToggle, handleThemeToggle } = useThemeToggle();
  return (
    <label
      className={`toggleContainer ${optClass}`}
      title={!themeToggle ? "Light mode" : "Dark mode"}
      aria-label={!themeToggle ? "Light mode" : "Dark mode"}
    >
      <input
        type="checkbox"
        checked={!themeToggle}
        onChange={handleThemeToggle}
      />
      <div />
    </label>
  );
};

export default ThemeToggleBtn;
