import React from "react";
import { formatComma } from "../../utils/formatNumber";

export function AmountActionButton({ icon, text, value, loading }) {
  return (
    <div className="grow flex items-center justify-between rounded-xl bg-gradient-to-br from-indigo-800 to-indigo-950 light:from-[--indigo-purple-dark] light:to-neutral-200 p-5 light:shadow-[0_0_8px_-5px] light:shadow-neutral-400">
      <div>
        {loading ? (
          <p className="w-36 h-8 skeleton mb-2 bg-[--indigo-purple-light] light:bg-transparent"></p>
        ) : (
          <p className="text-3xl text-white  font-semibold">
            ${formatComma(value)}
          </p>
        )}
        <p className="text-stone-300 light:text-white">{text}</p>
      </div>
      {icon}
    </div>
  );
}
