import React from "react";
import { Input } from "./Input";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import UseProfile from "../../hooks/useProfile";
import Loading from "../../components/shared/Loading";

export function ProfileInputs() {
  const {
    loading,
    user,
    formData,
    handleInputChange,
    handleReset,
    handleSubmit,
  } = UseProfile();

  return (
    <>
      {loading ? (
        <Loading optClass={" block mx-auto mt-20 text-white"} />
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-10 lg:gap-x-20 xl:gap-x-40 gap-y-5 items-center ">
            <Input
              label="First Name"
              name="first_name"
              value={formData.first_name}
              onChange={handleInputChange}
              disabled={false}
            />
            <Input
              label="Last Name"
              name="last_name"
              value={formData.last_name}
              onChange={handleInputChange}
              disabled={false}
            />
            <Input
              label="Birth"
              name="birth"
              value={formData.birth}
              type="date"
              onChange={handleInputChange}
              disabled={false}
            />
            <Input label="phone" value={user.phone} disabled={true} />

            {user.country && (
              <Input
                label="Country"
                value={user.country.name}
                disabled={true}
              />
            )}
            <Input
              label="Mail"
              value={user.email}
              type="email"
              disabled={true}
            />
          </div>

          <div className="mt-10 text-center  text-white">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
              <button
                onClick={handleReset}
                className=" bg-[var(--indigo-purple)] hover:bg-[var(--indigo-purple-hover)] rounded-lg pt-1 pb-0.5 px-5"
              >
                Reset To Default
              </button>
              <button
                onClick={handleSubmit}
                className=" bg-btnYellow rounded-lg font-bold text-[#222222] pt-1 pb-0.5 px-5"
              >
                Save Changes
              </button>
            </div>
            <Link
              to={"/dashboard/tickets"}
              className="hover:text-[--indigo-purple-light] transition-colors group"
            >
              <p className="opacity-80 my-3">
                Have A Problem?{" "}
                <span className="underline underline-offset-4 !decoration-[--indigo-purple-light] group-hover:no-underline">
                  Ticket Now
                </span>
              </p>
            </Link>
          </div>
        </>
      )}
    </>
  );
}
