import React from "react";
import ReactApexChart from "react-apexcharts";
import ChallengeStatistics from "../../../utils/ChallengeStatistics";

const defaultOptions = {
  // border radius
  stroke: {
    lineCap: "round",
  },

  plotOptions: {
    radialBar: {
      // unProgressed area color
      track: {
        background: "#383838",
      },
      // bar
      hollow: {
        size: "70%",
      },
      startAngle: -90,
      endAngle: 90,
      dataLabels: {
        show: true,

        //  'series' style
        value: {
          color: "transparent",
        },
      },
    },
  },
  labels: [""],
  chart: {
    type: "radialBar",
  },
};

// value of each box

// layout
export function AnalysisStatus({ data, dataArchive, dataChallenge }) {
  const stats = new ChallengeStatistics(data, dataChallenge, dataArchive);
  const totalDays = dataChallenge.challenge_period;
  const statistics = stats.getAllStatistics();
  const StatusItems = [
    {
      options: {
        // enable default options
        ...defaultOptions,

        // progressed area color
        fill: {
          colors:
            maxPermitted(data.max_permitted_loss, statistics.loss)[0] < 100
              ? ["#44ff00"]
              : ["#f00"],
        },
      },
      series: maxPermitted(data.max_permitted_loss, statistics.loss),
      title: "Drawdown",
      centerValue: `${statistics.loss}$`,
      mainText: "Max Value Reached",
      footer: `Max Allowed: `,
      allowed: `${data.max_permitted_loss}$`,
    },
    {
      options: {
        ...defaultOptions,

        // progressed area color
        fill: {
          colors:
            data.max_permitted_daily_loss === "0.00"
              ? ["#00f"]
              : maxPermitted(
                  data.max_permitted_daily_loss,
                  statistics.dailyLoss
                )[0] < 100
              ? ["#00f"]
              : ["#f00"],
        },
      },
      series:
        data.max_permitted_daily_loss === "0.00"
          ? [0]
          : maxPermitted(data.max_permitted_daily_loss, statistics.dailyLoss),
      title: "Daily Drawdown",
      centerValue: `${statistics.dailyLoss}$`,
      mainText: "Max Value Reached",
      footer: `Max Allowed: `,
      allowed:
        data.max_permitted_daily_loss === "0.00"
          ? "No limit"
          : `${data.max_permitted_daily_loss}$`,
    },
    {
      options: {
        ...defaultOptions,

        // progressed area color
        fill: {
          colors: ["#FF7A00"],
        },
      },
      series: maxPermitted(totalDays, statistics.daysAvailable),
      title: "Trading Period",
      centerValue: `${statistics.centerValueTime}Days`,
      mainText: "Max value reached",
      footer: "Max Allowed: ",
      allowed: `${statistics.titleValueTime}`,
    },
  ];

  function maxPermitted(max, num) {
    const res = (parseInt(num) / parseInt(max)) * 100;
    if (res === Infinity) {
      return [100];
    }
    return [res];
  }
  // function tradingPeriods(max,num) {
  //   const res = (num / max) * 100;
  //   return [res];
  // }

  return (
    <>
      <p className="font-bold text-2xl text-white leading-none mt-10 ">
        The status of your analysis
      </p>
      <div className=" grid md:grid-cols-2 lg:grid-cols-3 gap-5 mt-2">
        {StatusItems.map((item) => (
          <AnalysisStatusItem data={item} />
        ))}
      </div>
    </>
  );
}

// box
function AnalysisStatusItem({ data }) {
  return (
    <div className="flex flex-col bg-primaryPurple light:bg-[--indigo-purple-dark] light:border-[--indigo-purple-hover] light:shadow-[--indigo-purple-hover] rounded-2xl border border-[#4743e080] shadow-[0_0_5px_0] shadow-[#4743e080] text-center py-14 relative mt-3">
      <span className="text-lg text-nowrap text-white absolute top-8  translate-x-1/2 right-1/2 font-semibold">
        {data.title}
      </span>
      <div className="-mb-8">
        <ReactApexChart
          options={data.options}
          series={data.series}
          height={300}
          type="radialBar"
        />
      </div>
      <span className="text-white capitalize absolute translate-x-1/2 right-1/2 bottom-[calc(50%+20px)] translate-y-1/2 font-bold text-2xl">
        {data.centerValue}
      </span>
      <div className="h-full flex flex-col justify-end">
        {data.title === "trading periods" ? (
          <span className="capitalize  text-md lg:text-lg font-bold w-full text-center text-orange-500">
            {data.series.at(0) === 0 && "Your time has been finished."}
          </span>
        ) : (
          <span className="capitalize text-md lg:text-lg font-bold w-full text-center text-red-500">
            {data.series.at(0) >= 100 && "Reached to max allowed limit"}
          </span>
        )}
        <p>
          <span className="capitalize text-green-500 font-bold">
            {data.footer}
          </span>
          <br />
          <span className="capitalize text-white">{data.allowed}</span>
        </p>
      </div>
    </div>
  );
}
