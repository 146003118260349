import React from "react";

export function PhoneNumber({ children }) {
  return (
    <>
      <p className="text-[#BDBDBD] light:text-neutral-600 text-center text-lg uppercase tracking-widest ">
        Please verify your phone number
      </p>
      <img
        src="../message-sent-successfully-4967399-4137430 1.png"
        className="w-1/2"
        alt=""
      />

      {children}
    </>
  );
}
