import { useState } from "react";
import ErrorText from "../../components/shared/ErrorText";
export function PaymentInput({ func, value }) {
  const [error, setError] = useState(false);

  function handleError(e) {
    e.preventDefault();
    setError(true);
  }

  function handleChange(e) {
    func(e.target.value);
    setError(false);
  }
  return (
    <>
      <div className=" rounded-lg overflow-hidden border-l-4 border-primaryPurple light:border-[--indigo-purple] relative ">
        <input
          value={value}
          onChange={handleChange}
          onInvalid={handleError}
          required={true}
          type="text"
          autoFocus
          pattern="T[A-Za-z0-9]{33}"
          className="w-full text-lg text-black font-semibold placeholder:font-normal bg-white p-2 px-4 focus:outline-0"
          placeholder="TRX Address"
        />
        <div className="w-7 h-auto absolute top-[10px] right-2"></div>
      </div>
      <ErrorText optClass="ml-2" text={"Invalid Address"} error={error} />
    </>
  );
}
