import React from "react";
import { ToastContainer } from "react-toastify";
export function ListLogOutItem({ icon, text, loading, handleLogOut }) {
  return (
    <>
      <ToastContainer theme="dark" />
      {loading ? (
        <li className="w-full text-center py-1.5 pl-4 mb-4">
          <span className="loading loading-dots loading-sm"></span>
        </li>
      ) : (
        <li
          onClick={handleLogOut}
          className={`w-full flex items-center gap-2 hover:bg-[--indigo-purple-light] py-1.5 pl-4 mb-4 hover:cursor-pointer transition-all `}
        >
          {icon}
          <span className="text-lg capitalize pt-1">{text} </span>
        </li>
      )}
    </>
  );
}
