export default function StepindicatorContainer({
  headerText,
  optClass = "",
  children,
}) {
  return (
    <div className={`w-full mt-5 mb-3 ${optClass}`}>
      <p className="text-3xl text-white font-bold mb-3 first-letter:uppercase">
        {headerText}
      </p>
      <ol className=" flex items-start">{children}</ol>
    </div>
  );
}
