import React from "react";
// import { Loginfooter } from "./Loginfooter";
import { InputsContainer } from "../shared/InputsContainer";
import { ImageContainer } from "../shared/ImageContainer";
import { Player } from "@lottiefiles/react-lottie-player";
import { useForgotPassword } from "../../../hooks/useForgotPassword";
import AuthInput from "../../../components/Auth/AuthInput";
import { AuthSubmitBtn } from "../../../components/Auth/AuthSubmitBtn";
import Loading from "../../../components/shared/Loading";
export default function ForgotPassword() {
  const { email, loading, error, handleSubmit, handleSetEmail } =
    useForgotPassword();
  return (
    <>
      <InputsContainer>
        <div className="relative ">
          <img
            src="../yellowStar.svg"
            className="w-14 absolute right-[30px] -top-2"
            alt=""
          />
          <p className="w-1/2  text-5xl text-white font-bold capitalize">
            forgot password
          </p>
        </div>

        <p className="text-white">
          Don’t worry! we can help you out. If you still remember your email
          address you can quickly reset your password. Just input that
          information in the field below and click on the button. We will send
          you an email that will link you to the password change website.
        </p>

        <hr className="border-dashed border-[--primary-text] opacity-50 border-t-2 my-10" />
        {loading ? (
          <div className="w-full text-center">
            <Loading />
          </div>
        ) : (
          <form onSubmit={(e) => e.preventDefault()} className="relative">
            <AuthInput
              dataTip="enter your email"
              label="email"
              value={email}
              onChange={(e) => {
                handleSetEmail(e.target.value);
              }}
              placeholder="johnsmith@gmail.com"
            />
            <span
              className={`absolute text-xs text-rose-600 font-semibold mt-2 ${
                error ? "block" : "hidden"
              } `}
            >
              Please Enter A Valid Email
            </span>
            <hr className="border-dashed border-[--primary-text] opacity-50 border-t-2 my-10" />
            <AuthSubmitBtn
              onClick={handleSubmit}
              title="Request password change"
            />
            <svg
              className="absolute -bottom-[35%] md:-bottom-[30%] right-0 md:-right-[20%]"
              width="65"
              height="63"
              viewBox="0 0 65 63"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M32.4218 0C36.4939 15.4471 49.0238 27.5624 65 31.4999C49.0238 35.4374 36.4939 47.5526 32.4218 63C28.5061 47.5526 15.9762 35.4374 0 31.4999C15.9762 27.5624 28.5061 15.5985 32.4218 0Z"
                fill="#4743E0"
              />
            </svg>
          </form>
        )}

        {/* <Loginfooter title={"need help?"} linkText={"customer support"} /> */}
      </InputsContainer>
      <ImageContainer>
        <div className="flex items-center justify-center w-full h-full bg-[radial-gradient(circle,_rgba(60,52,196,0.4)_0%,_rgba(0,0,0,0)_70%)] light:bg-[radial-gradient(circle,_#00000000,_#00000000)]">
          <Player
            src="https://lottie.host/081816cb-d064-4bc8-acfc-7faf394ed8c6/tZkt5HgxI9.json"
            className="player md:w-[400px] md:h-400px lg:w-[700px] lg:h-[700px]"
            autoplay
            loop
          />
        </div>
      </ImageContainer>
    </>
  );
}
