import React, { useEffect, useRef } from "react";
import { IoArrowBack } from "react-icons/io5";
import Rules from "./Rules";

export default function Modal({ visible, setModalVisible, type }) {
  const modalRef = useRef(null);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    visible ? modalRef.current.showModal() : modalRef.current.close();
  }, [visible]);

  const handleClose = () => {
    setModalVisible(false);
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleClose();
  };

  const Rule = Rules[type];

  if (!Rule) {
    return <p>Rule not found</p>;
  }

  return (
    <dialog
      ref={modalRef}
      id="my_modal_1"
      className="modal"
      onCancel={handleESC}
    >
      <div className="modal-box w-11/12 md:w-full max-w-xl bg-indigo-950 py-5 relative px-2">
        <button
          onClick={handleClose}
          className="btn btn-sm btn-square bg-gradient-to-br from-indigo-700 to-indigo-800 border-0 text-[#fff] absolute top-5 left-5"
        >
          <IoArrowBack size={20} />
        </button>
        <div className="max-w-[350px] mx-auto">
          <div className="w-full  ">
            <Rule />
          </div>
        </div>
      </div>
    </dialog>
  );
}
