export function TransferDetail({ title, value }) {
  return (
    <div className="grid grid-cols-1 auto-rows-fr sm:grid-cols-2 items-center font-bold bg-[var(--indigo-purple-dark)] rounded-xl max-sm:border-t-2 sm:border-l-2 border-l-white light:border-l-[--indigo-purple]">
      <p className="text-white p-3 capitalize">{title}:</p>
      <div className="bg-white text-[var(--indigo-purple-dark)] light:text-[--primary-text] shadow-[0_0_10px_-2px] light:shadow-neutral-300 rounded-xl px-3 py-1 sm:py-3 h-full flex items-center">
        <p className="break-words w-full">{value}</p>
      </div>
    </div>
  );
}
