import React, { useRef, useState } from "react";
import Loading from "../../components/shared/Loading";
import { toast } from "react-toastify";
import { IoIosAttach } from "react-icons/io";
import { IoArrowBack } from "react-icons/io5";
import ErrorMessage from "../../components/shared/ErrorMessage";
import { useNewTicket } from "../../hooks/useNewTicket";
import { IoIosClose } from "react-icons/io";
export function NewTicketForm({ newTicketSuccess }) {
  const title = useRef();
  const message = useRef();
  const department = useRef();
  const modalClose = useRef();
  const fileUpload = useRef();
  const [uploadedFileName, setUploadedFileName] = useState("");
  const { loading, submitLoading, handleUploadTicket, departments } =
    useNewTicket();

  function handleFileUploadClick() {
    fileUpload.current.click();
  }

  function handleSetFileName(e) {
    setUploadedFileName(e.target.files[0].name);
  }

  const postTicket = async (e) => {
    e.preventDefault();
    if (
      department.current.value == null ||
      title.current.value == null ||
      message.current.value == null
    ) {
      toast.error("Please fill fields!");
      return;
    }

    if (
      fileUpload.current.files[0] &&
      fileUpload.current.files[0].size > 1048576
    ) {
      toast.error("The uploaded file exceeds the maximum size of 1 MB.");
      return;
    }

    const formData = new FormData();
    fileUpload.current.files[0] &&
      formData.append("file", fileUpload.current.files[0]);
    formData.append("department_id", department.current.value);
    formData.append("title", title.current.value);
    formData.append("message", message.current.value);

    await handleUploadTicket(formData, modalClose, newTicketSuccess);

    setUploadedFileName("");
    fileUpload.current.value = null;
    department.current.value = null;
    title.current.value = null;
    message.current.value = null;
  };
  function handleCancelFile() {
    setUploadedFileName("");
    fileUpload.current.value = "";
  }

  return (
    <dialog id="newTicket" className="modal !outline-0">
      <form
        onSubmit={postTicket}
        className={`modal-box bg-[var(--indigo-purple-dark)] w-full max-w-2xl mb-10 rounded-xl p-5 flex flex-col [&>*]:!outline-0  shadow-lg `}
      >
        <button
          type="button"
          onClick={() => modalClose.current.click()}
          className="btn btn-sm btn-square bg-[var(--indigo-purple)] hover:bg-[var(--indigo-purple-hover)] border hover:border-indigo-500 border-[--border-light] light:hover:border-orange-500 pb-1 text-white"
        >
          <IoArrowBack size={20} />
        </button>
        {loading || departments === null ? (
          <Loading />
        ) : (
          <div className="flex items-center gap-3 border-b-2 border-[--primary-text] px-2 mt-8">
            <label
              htmlFor="department"
              className="text-white text-nowrap capitalize label"
            >
              choose department:
            </label>
            <select
              required
              ref={department}
              id="department"
              className="w-full bg-transparent text-lg text-white font-semibold outline-0"
            >
              {departments?.map((department, key) => (
                <option
                  className="bg-[var(--indigo-purple-dark)]"
                  key={key}
                  value={department.id}
                >
                  {department.name}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="flex items-center gap-3 border-b-2 border-[--primary-text] px-2 mt-8">
          <label
            htmlFor="title"
            className="text-white text-nowrap capitalize label"
          >
            title:
          </label>
          <input
            ref={title}
            required={true}
            id="title"
            className="w-full bg-transparent text-lg text-white font-semibold outline-0 placeholder:text-white"
            type="text"
            placeholder=""
          />
        </div>

        <div className="mt-8">
          <label
            htmlFor="text"
            className="text-white text-nowrap capitalize label"
          >
            message:
          </label>
          <textarea
            ref={message}
            required={true}
            id="text"
            className="textarea border-2 border-[--primary-text] bg-transparent text-white  w-full p-2  focus:!border-[--primary-text] placeholder:text-white font-semibold"
            rows={3}
            placeholder="Your Message"
          />
        </div>
        <div className="flex flex-col-reverse md:flex-row md:items-center mt-2">
          {uploadedFileName && (
            <p className="grow max-w-[250px] rounded-md flex items-center gap-2 bg-[var(--indigo-purple)] text-white pl-2 my-2 md:my-0 ">
              <span className="inline-block w-full -mb-1 py-0.5 ">
                {uploadedFileName}
              </span>
              <button
                className="p-1 hover:bg-[var(--indigo-purple-hover)] rounded-lg"
                onClick={handleCancelFile}
              >
                <IoIosClose size={30} />
              </button>
            </p>
          )}

          <div className="grow flex items-center justify-end">
            <input
              type="file"
              ref={fileUpload}
              style={{ display: "none" }}
              accept="*"
              hidden
              onChange={handleSetFileName}
            />

            <button
              type="button"
              className="btn  bg-[var(--indigo-purple)] hover:bg-[var(--indigo-purple-hover)] text-white !border-transparent !outline-none max-w-32 gap-0 "
              onClick={handleFileUploadClick}
            >
              <IoIosAttach size={20} />
              Attach
            </button>
          </div>
        </div>
        <ErrorMessage
          text="The file must not be greater than 1MB."
          optcClass="mb-8"
        />

        <button
          disabled={submitLoading}
          className=" btn !bg-[var(--indigo-purple)] hover:bg-[var(--indigo-purple-hover)] border hover:border-indigo-500 border-[--border-light] light:hover:border-orange-500 !text-white !outline-0 disabled:opacity-70"
        >
          Send
          {submitLoading && (
            <span className="loading loading-dots loading-sm"></span>
          )}
        </button>
      </form>

      <form method="dialog" className="modal-backdrop">
        <button ref={modalClose}>close</button>
      </form>
    </dialog>
  );
}
