import React, { useEffect, useRef } from "react";
import { IoSwapVerticalOutline } from "react-icons/io5";

export default function Modal({
  visible,
  setModalVisible,
  transferData,
  setTransferData,
  handlePostTransfer,
}) {
  const modalRef = useRef(null);
  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    visible ? modalRef.current.showModal() : modalRef.current.close();
  }, [visible]);

  const handleClose = () => {
    setModalVisible(false);
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleClose();
  };

  function handleSetTransferData(value) {
    setTransferData((prev) => ({ ...prev, ...value }));
  }
  function handleSwap() {
    const from = transferData.from;
    const to = transferData.to;
    handleSetTransferData({ from: to, to: from });
  }

  return (
    <dialog
      ref={modalRef}
      id="my_modal_1"
      className="modal"
      onCancel={handleESC}
    >
      <div className="px-0 sm:p-10 modal-box w-11/12 md:w-8/12 max-w-2xl bg-indigo-950 light:bg-white">
        <span className="w-full block text-center text-white text-2xl font-semibold border-b-2 border-b-[--primary-text] mb-5">
          Trasnfer
        </span>
        <div className=" w-fit flex justify-center items-center flex-col mx-auto relative">
          <button
            onClick={handleSwap}
            className="absolute right-2 sm:-right-14 top-[102px] border border-[--border-light] p-1.5 rounded-xl !outline-0 hover:bg-indigo-700 light:hover:bg-[--indigo-purple-hover] transition-colors hover:border-transparent"
          >
            <IoSwapVerticalOutline size={30} color="var(--primary-text)" />
          </button>
          <label className="form-control w-full max-w-xs my-2">
            <div className="label">
              <span className="label-text text-white pl-12 font-semibold text-base ">
                From:
              </span>
            </div>
            <div className=" flex items-center justify-start gap-3">
              <svg
                className="w-12"
                viewBox="0 0 81 81"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M59.94 35.3804V9.46045"
                  stroke="var(--primary-text)"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M49.5746 19.8284L59.9426 9.46045L70.3106 19.8284"
                  stroke="var(--primary-text)"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M35.8992 12.8628H21.06C17.6228 12.8628 14.3265 14.2284 11.8961 16.6589C9.4656 19.0894 8.09998 22.3856 8.09998 25.8228V58.2229C8.09998 61.6599 9.4656 64.9563 11.8961 67.3866C14.3265 69.8172 17.6228 71.1829 21.06 71.1829H59.94C63.3773 71.1829 66.6737 69.8172 69.104 67.3866C71.5346 64.9563 72.9 61.6599 72.9 58.2229V45.0358"
                  stroke="var(--primary-text)"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.09998 32.3027H19.019C21.5969 32.3027 24.069 33.3267 25.8919 35.1495C27.7147 36.9723 28.739 39.4448 28.739 42.0228C28.739 44.6006 27.7147 47.073 25.8919 48.8958C24.069 50.7187 21.5969 51.7428 19.019 51.7428H8.09998"
                  stroke="var(--primary-text)"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <input
                disabled
                value={transferData.from === 1 ? " Wallet " : "Fund wallet"}
                type="text"
                placeholder="Transfer from..."
                className="input !bg-neutral-200 w-[300px] font-semibold disabled:text-black"
              />
            </div>
          </label>
          <label className="form-control w-full max-w-xs my-2">
            <div className="label">
              <span className="label-text text-white pl-12 font-semibold text-base ">
                To:
              </span>
            </div>
            <div className="w-full flex items-center justify-start gap-3">
              <svg
                className="w-12"
                viewBox="0 0 81 81"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M59.94 9.46045V35.3804"
                  stroke="var(--primary-text)"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M70.3106 25.0127L59.9426 35.3808L49.5746 25.0127"
                  stroke="var(--primary-text)"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M35.8992 12.8628H21.06C17.6228 12.8628 14.3265 14.2284 11.8961 16.6589C9.4656 19.0894 8.09998 22.3856 8.09998 25.8228V58.2229C8.09998 61.6599 9.4656 64.9563 11.8961 67.3866C14.3265 69.8172 17.6228 71.1829 21.06 71.1829H59.94C63.3773 71.1829 66.6737 69.8172 69.104 67.3866C71.5346 64.9563 72.9 61.6599 72.9 58.2229V45.0358"
                  stroke="var(--primary-text)"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.09998 32.3027H19.019C21.5969 32.3027 24.069 33.3267 25.8919 35.1495C27.7147 36.9723 28.739 39.4448 28.739 42.0228C28.739 44.6006 27.7147 47.073 25.8919 48.8958C24.069 50.7187 21.5969 51.7428 19.019 51.7428H8.09998"
                  stroke="var(--primary-text)"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <input
                disabled
                value={transferData.to === 1 ? " Wallet " : "Fund wallet"}
                type="text"
                placeholder="Transfer to..."
                className="input !bg-neutral-200 w-[300px] font-semibold disabled:text-black"
              />
            </div>
          </label>
          <label className="form-control w-full max-w-xs my-2">
            <div className="label">
              <span className="label-text text-white pl-12 font-semibold text-base ">
                Amount:
              </span>
            </div>
            <div className="w-full flex items-center justify-start gap-3">
              <svg
                className="w-12"
                viewBox="0 0 81 81"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1008_6328)">
                  <path
                    d="M13.5 16.875C13.5 15.0848 14.2112 13.3679 15.477 12.102C16.7429 10.8362 18.4598 10.125 20.25 10.125H60.75C62.5402 10.125 64.2571 10.8362 65.523 12.102C66.7889 13.3679 67.5 15.0848 67.5 16.875V64.125C67.5 65.9152 66.7889 67.6321 65.523 68.898C64.2571 70.1638 62.5402 70.875 60.75 70.875H20.25C18.4598 70.875 16.7429 70.1638 15.477 68.898C14.2112 67.6321 13.5 65.9152 13.5 64.125V16.875Z"
                    stroke="var(--primary-text)"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M27 27C27 26.1049 27.3556 25.2465 27.9885 24.6135C28.6215 23.9806 29.4799 23.625 30.375 23.625H50.625C51.5201 23.625 52.3786 23.9806 53.0115 24.6135C53.6444 25.2465 54 26.1049 54 27V30.375C54 31.2701 53.6444 32.1285 53.0115 32.7615C52.3786 33.3944 51.5201 33.75 50.625 33.75H30.375C29.4799 33.75 28.6215 33.3944 27.9885 32.7615C27.3556 32.1285 27 31.2701 27 30.375V27Z"
                    stroke="var(--primary-text)"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M27 47.25V47.2728"
                    stroke="var(--primary-text)"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M40.5 47.25V47.2728"
                    stroke="var(--primary-text)"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M54 47.25V47.2728"
                    stroke="var(--primary-text)"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M27 57.375V57.3978"
                    stroke="var(--primary-text)"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M40.5 57.375V57.3978"
                    stroke="var(--primary-text)"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M54 57.375V57.3978"
                    stroke="var(--primary-text)"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1008_6328">
                    <rect width="81" height="81" fill="var(--primary-text)" />
                  </clipPath>
                </defs>
              </svg>
              <input
                onChange={(e) =>
                  handleSetTransferData({ amount: e.target.value })
                }
                value={transferData.amount}
                type="text"
                placeholder="Type here"
                className="input !bg-neutral-100 w-[300px] font-semibold"
              />
            </div>
          </label>
          <div className="w-full self-end max-w-[300px] flex items-center justify-center gap-2 mt-3 pl-10">
            <button
              className="btn btn-outline btn-success font-bold text-lg w-[100px] my-2"
              onClick={handlePostTransfer}
            >
              Send
            </button>
            <button
              className="btn btn-outline btn-error font-bold text-lg w-[100px] my-2"
              onClick={handleClose}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </dialog>
  );
}
