import React, { useState } from "react";
import { MdOutlineCopyAll } from "react-icons/md";

export default function ReferralLink({ token }) {
  const [copy, setCopy] = useState(false);

  function handleCopy() {
    navigator.clipboard.writeText(token);
    setCopy(true);
  }
  return (
    <div className="w-full max-w-3xl  md:h-12 flex items-center flex-col md:flex-row gap-2 indigo-gradient rounded-xl border-l-2 border-l-white light:border-l-[--indigo-purple] px-2 py-2 md:py-0 mt-5 mb-5 light:shadow-[0_0_10px_-2px] light:shadow-neutral-300">
      <span className="block text-white text-lg font-semibold pt-1 leading-none">
        Your link
      </span>
      <div className="h-full grow flex items-center gap-3">
        <div className="h-2/3 grow flex items-center bg-white text-black font-semibold rounded-full px-2 py-2 md:py-0 leading-none ">
          {token}
        </div>
        <div className="tooltip" data-tip="copy">
          <MdOutlineCopyAll
            size={25}
            className={`cursor-pointer ${
              copy ? "fill-white" : "fill-gray-400"
            } transition-colors `}
            onClick={handleCopy}
          />
        </div>
      </div>
    </div>
  );
}
