import React from "react";
import { DepositAmount } from "./DepositAmount";
// import { TransactionResult } from "../shared/TransactionResult";
import { DepositPay } from "./DepositPay";
import { PaymentMethod } from "./PaymentMethod";
import PageStatus from "../../components/PageStatus/PageStatus";
import DepositConfirmation from "./DepositConfirmation";
import Loading from "../../components/shared/Loading";
import { useDeposit } from "../../hooks/useDeposit";
import StepIndicatorContainer from "../../components/indicator/StepIndicatorContainer";
import StepIndicator from "../../components/indicator/StepIndicator";

const stepIndicatorHeaders = [
  "choose your payment method",
  "enter your deposit amount",
  "review the transaction details",
  "Enter payment information",
];

export default function Deposit() {
  const {
    depositData,
    submitPayment,
    handleSetDepositData,
    cryptoRelocateloading,
  } = useDeposit();

  return (
    <>
      <PageStatus
        category={"finance"}
        currentPage={"deposits"}
        logoStarColor="#F8CC15"
      />
      {depositData.step !== null && (
        <StepIndicatorContainer
          headerText={
            stepIndicatorHeaders[depositData.step - 1] ??
            stepIndicatorHeaders[0]
          }
          optClass="max-w-2xl"
        >
          <StepIndicator
            num={1}
            text={"select payment method"}
            currentStep={depositData.step}
            onClick={handleSetDepositData}
          />

          <StepIndicator
            num={2}
            text={"transfer details"}
            currentStep={depositData.step}
            onClick={handleSetDepositData}
          />

          <StepIndicator
            num={3}
            text={"confirmation"}
            currentStep={depositData.step}
            onClick={handleSetDepositData}
          />

          <StepIndicator
            num={4}
            text={"pay"}
            last={true}
            currentStep={depositData.step}
            onClick={handleSetDepositData}
          />
        </StepIndicatorContainer>
      )}

      {depositData.step === 1 && (
        <PaymentMethod handleSetDepositData={handleSetDepositData} />
      )}
      {depositData.step === 2 && (
        <DepositAmount
          handleSetDepositData={handleSetDepositData}
          depositData={depositData}
        />
      )}
      {cryptoRelocateloading ? (
        <div className="w-full max-w-lg text-center">
          <Loading />
        </div>
      ) : (
        depositData.step === 3 && (
          <DepositConfirmation
            depositData={depositData}
            handleSetDepositData={handleSetDepositData}
            submitPayment={submitPayment}
          />
        )
      )}
      {depositData.step === 4 && <DepositPay depositData={depositData} />}
      {/* {depositData.confirmed && (
        <TransactionResult
          imgUrl={"../Group 101.png"}
          text={"your payment has been successfuly completed"}
        >
          <SubmitButton text="go to new challenge" />
          <SubmitButton
            onClick={() => setDepositData(intialData)}
            bgColor="bg-white"
            text="back"
            textColor="primaryPurple"
          />
        </TransactionResult>
      )} */}
    </>
  );
}
