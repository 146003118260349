import React from "react";
import { ToastContainer } from "react-toastify";
import ThemeToggleBtn from "../../../components/ThemeToggleBtn";
export default function AuthContainer({ children }) {
  return (
    <div className="w-full h-screen overflow-hidden flex bg-[#212121] light:bg-white">
      <ToastContainer />
      <ThemeToggleBtn optClass="absolute top-5 right-5 z-[555]" />
      {children}
    </div>
  );
}
