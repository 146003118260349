import React from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import useInitializer from "./hooks/useInitializer";
import AnimatedLogo from "./components/AnimatedLogo";
import useThemeToggle from "./hooks/useThemeToggle";

export default function App() {
  const { loading } = useInitializer();
  const { _ } = useThemeToggle();

  return (
    <>
      <BrowserRouter>
        <TawkMessengerReact
          propertyId="66260eb31ec1082f04e56175"
          widgetId="1hs2ait4b"
        />
        {loading ? (
          <div className="bg-primaryPurple light:bg-white w-full h-screen flex items-center justify-center">
            <AnimatedLogo />
          </div>
        ) : (
          <Router />
        )}
      </BrowserRouter>
    </>
  );
}
