import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../store/slices/auth";
import { usePostImage } from "../hooks/API/usePostImage";
import { usePost } from "../hooks/API/usePost";
const initialFormData = {
  first_name: "",
  last_name: "",
  birth: "",
  // Add more input fields here
};

const useCertificate = () => {
  const [formData, setFormData] = useState(initialFormData);
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null);
  const [showFile, setShowFile] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const { loading: imgLoading, handlePostImage } = usePostImage();
  const { loading, handlePostData } = usePost(false);

  useEffect(() => {
    setFormData({
      first_name: user.first_name,
      last_name: user.last_name,
      birth: user.birth ? user.birth.split(" ")[0] : null,
    });
  }, []);

  useEffect(() => {
    if (user.photo == null) {
      setSelectedImage("/UserProfilePhoto.png");
    } else {
      setSelectedImage(
        process.env.REACT_APP_API_BASE_URL + "/storage/" + user.photo
      );
    }
  }, [user.photo]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleReset = () => {
    setFormData({
      first_name: user.first_name,
      last_name: user.last_name,
      birth: user.birth ? user.birth.split(" ")[0] : null,
    });
    toast("Reset successful!");
  };

  const handleSubmit = async () => {
    postProfile();
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file.size > 1048576) {
      toast.error("The uploaded file exceeds the maximum size of 1 MB.");
      return;
    }
    setSelectedImage(URL.createObjectURL(file));
    setShowFile(true);
  };

  const handleUploadImage = async () => {
    postProfileImage();
  };

  const postProfile = async () => {
    const res = await handlePostData(
      "users/profile",
      formData,
      "Updated Successfully."
    );
    if (!res) return;
    await dispatch(authActions.setUser(res.user));
  };

  const postProfileImage = async () => {
    const formData = new FormData();
    formData.append("photo", fileInputRef.current.files[0]);

    const res = await handlePostImage(
      "users/profile/img",
      formData,
      "Updated Successfully."
    );
    if (!res) return;
    setShowFile(false);
    await dispatch(authActions.setUser(res.user));
  };

  return {
    imgLoading,
    loading,
    user,
    formData,
    selectedImage,
    showFile,
    fileInputRef,
    handleInputChange,
    handleReset,
    handleSubmit,
    handleButtonClick,
    handleImageChange,
    handleUploadImage,
  };
};

export default useCertificate;
