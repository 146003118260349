import React from "react";
import ReactApexChart from "react-apexcharts";
import { format } from "date-fns";

export function HistoryChart({ dataArchives, total }) {
  console.log(dataArchives);
  console.log(total);

  const balanceArray = dataArchives.map((obj) => obj.balance);
  const equityArray = dataArchives.map((obj) => obj.equity);
  const created_atArray = dataArchives.map((obj) =>
    format(new Date(obj.created_at), "MM/dd/yyyy H:i:s")
  );

  balanceArray.push(total.balance);
  equityArray.push(total.equity);
  created_atArray.push(format(new Date(total.updated_at), "MM/dd/yyyy H:i:s"));

  const options = {
    series: [
      {
        name: "equity",
        data: equityArray,
      },
      {
        name: "balance",
        data: balanceArray,
      },
    ],

    grid: {
      show: true,
      borderColor: "var(--chart-dash-grid)",
      strokeDashArray: 4,
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    stroke: {
      width: 3,
      curve: "straight",
    },
    chart: {
      background: "transparent",
      foreColor: "var(--primary-text)",
      toolbar: {
        show: false,
      },
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    colors: ["#FF0000", "#0000ff"],

    dataLabels: {
      enabled: false,
    },

    title: {
      text: "Balance and Drawdown Chart",
      align: "left",
      offsetY: 20,
      offsetX: 10,
      style: {
        fontSize: "20px",
        fontWeight: "semibold",
        // fontFamily: undefined,
        color: "var(--primary-text)",
      },
    },

    xaxis: {
      type: "datetime",
      categories: created_atArray,

      labels: {
        formatter: function (value, timestamp, opts) {
          return opts.dateFormatter(new Date(timestamp), "dd MMM HH:mm");
        },
      },
    },

    legend: {
      position: "top",
      horizontalAlign: "right",
      labels: {
        colors: "var(--primary-text)", // text color
        useSeriesColors: false,
      },
      itemMargin: {
        horizontal: 25,
      },
      markers: {
        offsetX: -4,
        offsetY: 2,
      },
    },
  };

  return (
    <>
      <p className="font-bold text-2xl text-white leading-none mt-10 mb-2">
        The status of your analysis
      </p>
      <div className="rounded-xl bg-primaryPurple light:bg-[--indigo-purple-dark]">
        <ReactApexChart
          options={options}
          series={options.series}
          type="line"
          height={650}
        />
      </div>
    </>
  );
}
