import React, { useState } from "react";
import { Link } from "react-router-dom";

export function PolicyAccept({ handleSetData, handleCurrentPage }) {
  const [selected, setSelected] = useState(null);
  const [error, SetError] = useState(false);

  function handleChange(e) {
    setSelected(e.target.value);
    SetError(false);
  }
  function handleSubmit() {
    if (selected) {
      handleSetData("policyLive", selected);
      handleCurrentPage("phone");
    } else {
      SetError(true);
    }
  }

  return (
    <div className="w-full flex flex-col text-white font-light">
      <p className="text-[#BDBDBD] light:text-neutral-600 text-center text-lg uppercase tracking-widest">
        let's get you verified
      </p>
      <p>
        Before you start, please prepare your identity document and make sure it
        is valid. We also require you to accept our T&Cs and agree to our
        processing of your personal data:
      </p>
      <fieldset onInvalid={(e) => e.preventDefault()}>
        <legend className="font-semibold my-4">
          I'm a resident or live in:
        </legend>
        <div className="flex items-center mb-3">
          <input
            onChange={handleChange}
            value="USA"
            required={true}
            id="USA"
            type="radio"
            name="whereLive"
            className="radio radio-xs mx-2 border-[--primary-text] "
          />
          <label htmlFor="USA" className="leading-none pt-1 cursor-pointer">
            United States of America
          </label>
        </div>
        <div className="flex items-center mb-3">
          <input
            onChange={handleChange}
            value="other"
            required={true}
            id="other"
            type="radio"
            name="whereLive"
            className="radio radio-xs mx-2 border-[--primary-text]"
          />
          <label
            htmlFor="other"
            className="leading-none pt-1 capitalize cursor-pointer"
          >
            other
          </label>
        </div>
      </fieldset>
      <span
        className={`text-sm text-rose-600 font-semibold ${
          error ? "block" : "hidden"
        } `}
      >
        Please select a option
      </span>
      <p className="my-12">
        By selecting "Agree and Continue", you agree to our{" "}
        <Link to={"https://golden.fund/en/terms-condition"} target="_blank">
          <span className="text-indigo-400 light:text-yellow-800 font-semibold hover:underline">
            Privacy Policy
          </span>
        </Link>{" "}
        and{" "}
        <Link to={"https://golden.fund/en/rules"} target="_blank">
          <span className="text-indigo-400 light:text-yellow-800 font-semibold hover:underline">
            Trading Rules.
          </span>
        </Link>{" "}
        Please review these documents to understand how we collect, use, and
        protect your information, as well as the rules governing your
        participation in Golden Fund.
      </p>
      <button
        onClick={handleSubmit}
        className="w-full max-w-xs font-semibold rounded-3xl bg-[var(--indigo-purple)] hover:bg-[var(--indigo-purple-hover)] text-white py-2 mt-10 mx-auto"
      >
        Agree and Continue
      </button>
    </div>
  );
}
