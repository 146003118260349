import React from "react";

export function SubmitButton({
  text = "continue",
  optClass,
  onClick,
  ...props
}) {
  return (
    <button
      onClick={onClick}
      {...props}
      className={`w-full max-w-xs text-2xl font-semibold transition-all rounded-lg px-6 pb-1.5 pt-2.5 mt-1 first-letter:capitalize bg-[var(--indigo-purple)] hover:bg-[var(--indigo-purple-hover)] text-white ${optClass}`}
    >
      {text}
    </button>
  );
}
