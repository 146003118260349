import React, { useState } from "react";
import { StatesContextProvider } from "../../Tickets/StatesContextProvider";
import { TicketFiltersAssign } from "../../Tickets/TicketFiltersAssign";
import { TicketRowsLayout } from "../../Tickets/TicketRowsLayout";
import Loading from "../../../components/shared/Loading";
import { useTicket } from "../../../hooks/useTicket";
import ErrorMessage from "../../../components/shared/ErrorMessage";
import Pagination from "../../../components/AdminShared/Pagination";
import RequestDetailsButton, {
  RequestDetailsButtonLayout,
} from "../../../components/AdminShared/RequestsDetailsButton";
import RequestDetailsInput from "../../../components/AdminShared/RequestsDetailsInput";
import PageStatus from "../../../components/PageStatus/PageStatus";

const initialFilter = {
  title: "",
  state: "",
  from: "",
  to: "",
};

export default function AdminTickets() {
  const [ticketType, setTicketType] = useState(1);
  const [pagination, setPagination] = useState(1);
  const [query, setQuery] = useState("");
  const { loading, error, tickets, getTickets, setType } = useTicket(
    pagination,
    query,
    "admin/tickets/answer-list"
  );
  const [filter, setFilter] = useState(initialFilter);

  function handleTicketType(value) {
    if (value === 1) {
      setType("admin-unAssigned");
      setPagination(1);
    } else {
      setType("admin-assigned");
      setPagination(1);
    }
    setTicketType(value);
  }

  function handleSubmitFilter(e) {
    e.preventDefault();
    let query = "";
    if (filter.title) {
      query += `&title=${filter.title}`;
    }
    if (filter.state) {
      query += `&state=${filter.state}`;
    }
    if (filter.from) {
      query += `&from_date=${filter.from}`;
    }
    if (filter.to) {
      query += `&to_date=${filter.to}`;
    }
    setPagination(1);
    setQuery(query);
    setType(ticketType === 1 ? "admin-unAssigned" : "admin-assigned");
  }

  function handleFilterChange(e) {
    setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  function handlePagination(event) {
    setPagination(event.selected + 1);
  }

  return (
    <>
      <PageStatus category="Support" currentPage="ticket" />
      <div className="w-full bg-[var(--indigo-purple-dark)] p-5 mt-5">
        <StatesContextProvider>
          <AdminTicketFilters
            setTicketType={handleTicketType}
            ticketLength={9}
          />

          {error ? (
            <ErrorMessage text="Error fetching data!" />
          ) : loading ? (
            <div className="min-h-[700px] flex items-center justify-center mt-10">
              <Loading />
            </div>
          ) : (
            <>
              <form onSubmit={handleSubmitFilter} className="mb-10 text-end">
                <div className="w-full grid grid-cols-2 lg:grid-cols-4 gap-x-5">
                  <RequestDetailsInput
                    title="Title"
                    value={filter.title}
                    name="title"
                    onChange={handleFilterChange}
                    titleClass="font-semibold"
                    placeholder="Ticket Title"
                  />
                  <label htmlFor="state" className="form-control">
                    <div className="label">
                      <span
                        className={`label-text text-slate-100 light:text-[--primary-text]`}
                      >
                        State:
                      </span>
                    </div>
                    <select
                      id="state"
                      name="state"
                      value={filter.state}
                      onChange={handleFilterChange}
                      className="select select-sm  text-black select-bordered bg-slate-300 light:bg-slate-100"
                    >
                      <option value="">All</option>

                      <option value={tickets.state[1]}>
                        {tickets.state[1]}
                      </option>
                      <option value={tickets.state[2]}>
                        {tickets.state[2]}
                      </option>
                      <option value={tickets.state[3]}>
                        {tickets.state[3]}
                      </option>
                    </select>
                  </label>
                  <RequestDetailsInput
                    title="Date From"
                    type="date"
                    value={filter.from}
                    name="from"
                    onChange={handleFilterChange}
                    titleClass="font-semibold"
                  />
                  <RequestDetailsInput
                    title="Date To"
                    type="date"
                    value={filter.to}
                    name="to"
                    onChange={handleFilterChange}
                    titleClass="font-semibold"
                  />
                </div>
                <RequestDetailsButtonLayout optClass="mt-5 justify-end">
                  <RequestDetailsButton
                    onclick={() => setFilter(initialFilter)}
                    optClass="btn-error"
                    title="Reset"
                  />

                  <RequestDetailsButton
                    onclick={handleSubmitFilter}
                    optClass="btn-success "
                    title="Filter"
                    type="submit"
                  />
                </RequestDetailsButtonLayout>
              </form>
              <TicketRowsLayout
                tickets={tickets.tickets.data}
                ticketType={ticketType}
                admin={true}
                setTicketType={setTicketType}
                getUnAssignedTickets={() => getTickets("admin-unAssigned")}
              />
            </>
          )}
        </StatesContextProvider>
        {tickets?.tickets && (
          <Pagination
            handleOnClick={handlePagination}
            data={tickets.tickets}
            optClass="mt-5"
          />
        )}
        {/* <ChatLayout /> */}
      </div>
    </>
  );
}

function AdminTicketFilters({ setTicketType }) {
  return (
    <div className="w-full flex items-center justify-between mb-5">
      <div className="flex items-center gap-x-3 ">
        <TicketFiltersAssign
          setTicketType={setTicketType}
          text={"unassigned"}
        />
        <TicketFiltersAssign setTicketType={setTicketType} text={"assigned"} />
      </div>
      {/* <div className="flex items-center gap-x-3">
        <TicketSearch />
        <TicketFiltersFilter />
      </div> */}
    </div>
  );
}
