import React from "react";

export function PlanPayBtn({ data, ...props }) {
  const balancePrice = data.balance_phases.filter(
    (item) => item.registration_fee !== 0 || item.pre_investment !== null
  )[0];

  return (
    <div className="w-full max-w-sm grid grid-cols-1 rounded-xl text-xl text-center font-bold  bg-gradient-to-b from-indigo-800 light:from-neutral-100 light:to-neutral-200 to-[var(--indigo-purple-dark)] mx-auto my-4 relative group light:shadow-[0_0_15px_-10px] light:shadow-neutral-400">
      <span
        translate="no"
        className="w-full pt-2 text-white text-2xl md:text-3xl group-hover:border-yellow"
      >
        $
        {balancePrice.registration_fee != null &&
        balancePrice.registration_fee > 0
          ? balancePrice.registration_fee
          : balancePrice.pre_investment}
      </span>

      <button
        disabled={props.walletLoading}
        onClick={props.onClick}
        className="w-full text-[var(--indigo-purple-dark)] light:text-[--primary-text] hover:text-white transition-all border border-transparent hover:border-neutral-300 bg-white hover:bg-indigo-800 rounded-xl p-2 pt-2.5 capitalize text-2xl md:text-3xl font-bold disabled:cursor-not-allowed disabled:opacity-40 disabled:!text-[var(--indigo-purple-dark)] disabled:!bg-white light:hover:border-[--indigo-purple] disabled:hover:!border-transparent light:hover:bg-[--indigo-purple-hover]"
      >
        {props.walletLoading ? (
          <span className="loading loading-dots loading-sm bg-black opacity-100" />
        ) : (
          <span>Continue</span>
        )}
      </button>
    </div>
  );
}
