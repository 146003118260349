import React from "react";

export default function ReferralsInfoRow({ icon, text, value }) {
  return (
    <div className="w-full max-w-4xl h-12 flex items-center gap-2 indigo-gradient rounded-xl border-l-2 border-l-white light:border-l-[--indigo-purple] p-2 my-7 relative light:shadow-[0_0_10px_-2px] light:shadow-neutral-300">
      {icon}
      <span className="block text-white text-base md:text-xl  font-semibold pt-1 leading-none">
        {text}
      </span>
      <div className=" w-fit min-w-32 h-8 flex items-center justify-center bg-white text-[var(--indigo-purple-hover)] font-bold rounded-md px-5 absolute right-0 top-full -translate-y-1/2 pt-0.5">
        {value}
      </div>
    </div>
  );
}
