import React, { useEffect, useRef, useState } from "react";
import { PlanSetupIcon } from "./PlanSetupIcon";
import { PlanSetupTable } from "./PlanSetupTable";
import { PlanBalanceBtnLayout } from "./PlanBalanceBtnLayout";
import { PlanBalanceBtn } from "./PlanBalanceBtn";
import { PlanPayBtn } from "./PlanPayBtn";
import Slider from "react-slick";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../components/shared/Loading";
import { useNewChallenge } from "../../hooks/useNewChallenge";
import InactiveTimeCountDown from "./InactiveTimeCountDown";
import { getChallenges } from "../../store/slices/challenge";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
const settings = {
  initialSlide: 2,
  infinite: true,
  className: "center",
  centerMode: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 1120,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const brokerImg = {
  Swissquote: "../swissquote-broker.png",
  Uniglobe: "../uniglogbe-broker.png",
  HYCM: "../hycm-broker.png",
  Axi: "../axi-broker.png",
  OANDA: "../oanda_broker.png",
  FXTM: "../fxtm_broker.png",
};

export function PlanInfo({ data, setData, challenges, setPayData }) {
  const {
    challenge,
    balance,
    platform,
    broker,
    wallet,
    balancePrice,
    setBalance,
    handlePlatformChange,
    setBroker,
    brokers,
    walletLoading,
  } = useNewChallenge(data);
  const [ended, setEnded] = useState(false);
  const [visible, setVisible] = useState(false);
  const [swiperItem, setSwiperItem] = useState(0);

  const sliderRef = useRef(null);
  const dispatch = useDispatch();

  function handleRefresh() {
    dispatch(getChallenges());
    window.location.reload();
  }

  function handleBrokerSelect(name) {
    setBroker(name);
    setVisible(false);
  }

  const ContinueToPay = () => {
    if (!broker) return toast.error("Please select a broker!");
    if (!platform) return toast.error("Please select a platform!");
    if (!challenge && !balance && !wallet) return;

    const data = {
      challenge: challenge?.name,
      discountable: challenge?.discountable,
      balance: balance?.amount,
      balance_id: balance?.id,
      platform: platform,
      broker: broker,
      wallet: wallet,
      price:
        balancePrice.registration_fee != null &&
        balancePrice.registration_fee > 0
          ? balancePrice.registration_fee
          : balancePrice.pre_investment,
    };
    setData((prev) => ({ ...prev, step: 3 }));
    setPayData(data);
  };

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(data.order);
    }
  }, [data.order]);

  return (
    <div className="max-w-7xl mx-auto relative">
      <div className="slider-container mb-8 [&_.slick-prev]:translate-x-8 [&_.slick-prev]:z-10 [&_.slick-prev]:scale-150 [&_.slick-next]:-translate-x-8 [&_.slick-next]:z-10 [&_.slick-next]:scale-150">
        <Slider
          {...settings}
          ref={sliderRef}
          key={data.order}
          beforeChange={(currentItem, nextItem) => {
            setData((prev) => ({
              ...prev,
              accountType: challenges[nextItem % challenges.length].name,
            }));
            setSwiperItem(nextItem % challenges.length);
          }}
        >
          {challenges.map((challenge, i) => (
            <div>
              <div className="w-full flex items-center justify-center py-5">
                <PlanSetupIcon
                  data={challenge}
                  key={challenge.name}
                  active={i === swiperItem}
                  setData={setData}
                />
              </div>
            </div>
          ))}
          {challenges.map((challenge, i) => (
            <div>
              <div className="w-full flex items-center justify-center py-5">
                <PlanSetupIcon
                  data={challenge}
                  key={i}
                  active={i % 4 === swiperItem}
                  setData={setData}
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
      {!balance ? (
        <div className="w-full h-[720px] bg-[var(--indigo-purple-dark)] rounded-2xl flex items-center justify-center">
          <Loading />
        </div>
      ) : (
        <PlanSetupTable data={balance} />
      )}
      <PlanBalanceBtnLayout>
        {!balance || !challenge ? (
          <>
            <div className="w-full h-[40px] indigo-gradient rounded-xl">
              <span className="skeleton w-full h-full bg-transparent" />
            </div>
            <div className="w-full h-[40px] indigo-gradient rounded-xl">
              <span className="skeleton w-full h-full bg-transparent" />
            </div>
          </>
        ) : (
          challenge.challenge_balances.map(
            (btn, index) =>
              btn.status === "active" && (
                <PlanBalanceBtn
                  balance={balance}
                  key={index}
                  id={btn}
                  setBalance={setBalance}
                />
              )
          )
        )}
      </PlanBalanceBtnLayout>
      <div className="flex flex-col xl:flex-row xl:items-center justify-between relative gap-5 !z-10 my-10">
        <div className="flex flex-col md:flex-row justify-between xl:justify-normal items-center md:gap-3 ">
          <label
            htmlFor="chooseBroker"
            className="text-white text-lg text-nowrap capitalize"
          >
            choose your platform:
          </label>

          <fieldset
            className="flex justify-between rounded-lg gap-6 p-1 indigo-gradient w-[285px] outline-0 light:shadow-[0_0_8px_-5px] light:shadow-neutral-400"
            onChange={(e) => handlePlatformChange(e.target.value)}
          >
            <label className="label gap-1 cursor-pointer">
              <img
                src="../mt4.webp"
                className="w-7 h-7 max-w-10 max-h-10 rounded-lg"
                alt=""
              />
              <span className="label-text pt-1 ml-1 mr-0.5 text-white font-semibold">
                MT4
              </span>
              <input
                value="MT4"
                checked={platform === "MT4"}
                type="radio"
                name="platform"
                className="radio radio-sm border border-white light:border-[--primary-text]"
              />
            </label>
            <label className="label gap-1 cursor-pointer">
              <img
                src="../mt5.webp"
                className="w-7 h-7 max-w-10 max-h-10 rounded-lg"
                alt=""
              />
              <span className="label-text pt-1 ml-1 mr-0.5 text-white font-semibold">
                MT5
              </span>
              <input
                value="MT5"
                checked={platform === "MT5"}
                type="radio"
                name="platform"
                className="radio radio-sm border-white light:border-[--primary-text]"
              />
            </label>
          </fieldset>
        </div>
        <div className="flex flex-col md:flex-row justify-between xl:justify-normal items-center md:gap-3 mt-5 lg:mt-0">
          <label
            htmlFor="chooseBroker"
            className="text-white text-lg text-nowrap capitalize"
          >
            choose your broker:
          </label>

          <div className="w-[285px] text-white relative">
            <button
              onClick={() => setVisible((prev) => !prev)}
              className="w-full flex items-center justify-between indigo-gradient px-2 h-[55px] rounded-lg cursor-pointer "
            >
              <div className="w-full flex items-center gap-2">
                <img
                  src={brokerImg[broker] || "../newChallengeBroker.png"}
                  className="max-w-[70px]"
                  alt=""
                />
                {broker === null ? (
                  <span>please select broker</span>
                ) : (
                  <span>{broker}</span>
                )}
              </div>
              <svg
                class="h-5 w-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
                data-slot="icon"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.53 3.47a.75.75 0 0 0-1.06 0L6.22 6.72a.75.75 0 0 0 1.06 1.06L10 5.06l2.72 2.72a.75.75 0 1 0 1.06-1.06l-3.25-3.25Zm-4.31 9.81 3.25 3.25a.75.75 0 0 0 1.06 0l3.25-3.25a.75.75 0 1 0-1.06-1.06L10 14.94l-2.72-2.72a.75.75 0 0 0-1.06 1.06Z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            <ul
              role="listbox"
              className={`${
                visible ? "opacity-100 mt-2 " : "opacity-0 h-0 overflow-hidden"
              } transition-all w-full indigo-gradient shadow-[0_0_8px_-5px] shadow-[#00000083] rounded-lg flex flex-col gap-3 [&>li]:transition-all [&>li]:p-2 [&>li]:rounded-lg [&>li]:cursor-pointer absolute px-2 z-10`}
            >
              {brokers.map((item) => (
                <li
                  onClick={() => handleBrokerSelect(item)}
                  key={item}
                  role="option"
                  aria-selected={item === broker ? "true" : "false"}
                  className="w-full flex gap-2 items-center hover:bg-[var(--indigo-purple-hover)] first:mt-2 last:mb-2"
                >
                  <img
                    src={brokerImg[item] || "../newChallengeBroker.png"}
                    alt=""
                    className="max-w-[50px] touch-none"
                  />
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {challenge?.state !== 1 && (
        <div className="flex items-center justify-center">
          <div className="w-full max-w-sm text-white bg-gradient-to-b from-indigo-800 to-[var(--indigo-purple-dark)] light:from-neutral-100 light:to-neutral-200 rounded-xl light:shadow-[0_0_15px_-10px] light:shadow-neutral-400">
            <div className="text-center text-2xl p-3 font-semibold">
              $
              {balancePrice.registration_fee != null &&
              balancePrice.registration_fee > 0
                ? balancePrice.registration_fee
                : balancePrice.pre_investment}
            </div>
            {ended ? (
              <button
                onClick={handleRefresh}
                className="w-full text-[var(--indigo-purple-dark)]  bg-white rounded-xl p-2 pt-2.5 capitalize text-lg font-semibold hover:underline"
              >
                click here to update data
              </button>
            ) : (
              <div className="text-center bg-white text-[var(--indigo-purple-dark)] light:text-neutral-500 light:bg-neutral-300 rounded-xl p-3 font-semibold cursor-not-allowed">
                {!challenge?.activate_time && (
                  <span className="text-2xl"> Not Active</span>
                )}
                {challenge?.activate_time && (
                  <>
                    <span className="mb-2 block"> Activate in:</span>
                    <InactiveTimeCountDown
                      activeTime={challenge.activate_time}
                      textSize="text-4xl"
                      typeSkeleton={false}
                      setEnded={setEnded}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {balance != null && challenge?.state === 1 && (
        <PlanPayBtn
          onClick={ContinueToPay}
          data={balance}
          walletLoading={walletLoading}
        />
      )}
      {/* <img
            src="../dashboardBgWave.png"
            className="absolute top-0 mix-blend-color-burn"
            alt=""
          /> */}
    </div>
  );
}
