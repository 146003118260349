import { getData, putData } from "../../utils/fetchData";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
const initialFormData = {
  id: null,
  month: null,
  fund: null,
  total_fund_under_managment: null,
  avg_total_profit: null,
};
export function usePlans() {
  const [type, setType] = useState("index");
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  const getPlans = async () => {
    setLoading((prev) => true);
    setError((prev) => false);

    const res = await getData("admin-dashboard-plans-summary");

    if (res?.data.dashboard_plans_summary) {
      if (res.data.dashboard_plans_summary.length === 0) {
        toast.success("successfully fetched");
        setError((prev) => "Data not found!");
      } else {
        setPlans(res.data.dashboard_plans_summary);
      }
    } else {
      toast.error(res?.data.message || "Unexpected error");
      setError((prev) => "Error fetching data!");
    }

    setLoading((prev) => false);
  };

  const handleUpdate = async () => {
    setLoading((prev) => true);

    const res = await putData(
      "admin-dashboard-plans-summary/" + formData.id,
      formData
    );
    if (res?.data) {
      toast.success("successfully updated!");
      getPlans();
      setType((prev) => "index");
    } else {
      setLoading((prev) => false);
      toast.error(res?.data.message || "Unexpected error!");
    }
  };

  useEffect(() => {
    getPlans();
  }, []);

  return {
    loading,
    error,
    type,
    setType,
    plans,
    formData,
    setFormData,
    handleUpdate,
  };
}
