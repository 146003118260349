import React from "react";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { formatComma } from "../../utils/formatNumber";

export function RowTitles({ data }) {
  const navigate = useNavigate();
  function calculateDaysAvailable() {
    if (!data.end_time) return false;
    const currentDate = new Date();
    const endDate = new Date(data.end_time);
    const timeDifference = endDate - currentDate;
    const daysAvailable = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return daysAvailable > 0 ? daysAvailable : -1;
  }
  const remaningDays = calculateDaysAvailable();

  return (
    <ul className="grid grid-cols-7 justify-items-center text-center text-base text-white font-semibold capitalize px-5 ">
      <li className="flex flex-col items-center w-full">
        <span className="bg-green-500 rounded-md px-2 w-full">
          {data.status}{" "}
        </span>
        <span className="font-normal text-sm mt-1 text-red-600">
          ({data.phase_type_name})
        </span>
      </li>
      <li className="font-light">${formatComma(data.balance)}</li>
      <li className="font-light capitalize">{data.challenge_type_name}</li>
      <li>
        {data.scale_up_percentage ? (
          <IoMdCheckmarkCircleOutline size={25} className="fill-green-500" />
        ) : (
          <IoMdCloseCircleOutline size={25} className="fill-red-500" />
        )}
      </li>
      <li className="font-light">{data.start_time}</li>
      <li className="flex flex-col items-center ">
        <span className="rounded-md px-2 font-light pt-1">
          {data.end_time ?? "not set"}
        </span>
        {remaningDays && (
          <span className="font-normal text-sm mt-1 text-red-600">
            {remaningDays !== -1 ? (
              <>{remaningDays} Days remaining</>
            ) : (
              "Expired"
            )}
          </span>
        )}
      </li>
      <li
        onClick={() => navigate("analyze/" + data.id)}
        className="w-full max-w-[90%] h-fit bg-amber-500 hover:bg-amber-600 text-primaryPurple light:bg-indigo-500 light:hover:bg-indigo-600 light:text-[#fff] cursor-pointer font-bold pt-0.5 rounded-md z-50"
      >
        <span>Analyze</span>
      </li>
    </ul>
  );
}
