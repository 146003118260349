import React, { useState } from "react";
import { SubmitButton } from "../../components/shared/SubmitButton";
import { toast } from "react-toastify";
const data = [
  {
    title: "Crypto",
    fee: "0%",
    imgSrc: "../../Crypto.png",
    descImages: [
      "../../usdt.png",
      "../../bitcoin.png",
      "../../ethereum.png",
      "../../ton.png",
    ],
  },
  {
    title: "Fiat",
    fee: "5%",
    imgSrc: "../../Fiat.png",
    descImages: [
      "../../wise.png",
      "../../master-1.png",
      "../../american-express.png",
      "../../master-2.png",
      "../../visa.png",
    ],
  },
];

export function PaymentMethod({ handleSetDepositData }) {
  const [selected, setSelected] = useState("Crypto");

  function handleMethod() {
    if (!selected) {
      toast.error("Please select a payment method!");
      return;
    }
    handleSetDepositData({
      paymentMethod: selected,
      step: 2,
    });
  }

  return (
    <>
      <div className="w-full flex flex-col sm:flex-row items-stretch justify-between max-w-3xl gap-3 mx-auto mt-10">
        {data.map((item) => (
          <button
            onClick={() => setSelected(item.title)}
            className={`indigo-gradient light:!bg-gradient-to-br light:from-neutral-50 light:to-neutral-200 hover:opacity-60 hover:border-[--indigo-purple-light] rounded-lg sm:w-1/2 px-3 py-5 border transition-opacity shadow-[0_0_8px_-2px] light:shadow-neutral-400 ${
              selected === item.title
                ? "!opacity-100 !border-[--border-light] light:!shadow-[--indigo-purple-hover]"
                : "opacity-40 border-transparent"
            }`}
          >
            <div className="flex items-center justify-between">
              <div className="text-left">
                <p className="text-xl text-white font-bold">{item.title}</p>
                <p className="text-sm text-indigo-300 ">fee: {item.fee}</p>
              </div>
              <div className="w-14 h-14 sm:w-20 sm:h-20 rounded-md">
                <img
                  src={item.imgSrc}
                  className="w-full h-full opacity-80"
                  alt=""
                />
              </div>
            </div>
            <div className="flex items-center justify-left gap-x-4 mt-3">
              {item.descImages &&
                item.descImages.map((imgUrl) => (
                  <div>
                    <img src={imgUrl} className="w-8 sm:w-10" alt="" />
                  </div>
                ))}
            </div>
          </button>
        ))}
      </div>
      <div className="w-full flex items-center mt-10">
        <SubmitButton optClass={"mx-auto "} onClick={handleMethod} />
      </div>
    </>
  );
}
