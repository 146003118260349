import React from "react";
import Chart from "react-apexcharts";
import { useState, useEffect } from "react";
import { getPlans } from "../../utils/requests/dashboardRequest";
import Loading from "../../components/shared/Loading";

const barChartOptions = {
  plotOptions: {
    bar: {
      columnWidth: "30%",
      borderRadius: 7,
      borderRadiusApplication: "end",
    },
  },
  chart: {
    type: "bar",
    stacked: false,
    background: "var(--indigo-purple-dark)",

    foreColor: "var(--primary-text)",
    toolbar: {
      show: true,
      offsetX: 0,
      offsetY: 0,
      tools: {
        download: false,
        selection: false,
        zoom: false,
        zoomin: true,
        zoomout: true,
        pan: false,
      },
    },
  },
  grid: {
    show: true,
    position: "front",
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: [0, 0, 3],
    curve: "smooth",
  },
  title: {
    text: "Plans Summary Chart",

    align: "left",
    offsetX: 20,
    offsetY: 10,
  },

  xaxis: {
    categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    title: {
      text: "Months ",
      margin: 10,
      offsetX: 0,
      offsetY: -10,
      floating: false,
      style: {
        fontSize: "14px",
        fontWeight: "normal",
        color: "#fff",
      },
    },
  },
  yaxis: [
    {
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: "#7635b3",
      },
      labels: {
        style: {
          colors: "#7635b3",
        },
      },
      title: {
        text: "Win-Win Fund (M$)",
        style: {
          fontSize: "16px",
          color: "#7635b3",
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    {
      min: 0,
      seriesName: "Total Fund Under Management (M$)",
      opposite: true,
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: "#1b97b3",
      },
      labels: {
        style: {
          colors: "#1b97b3",
        },
      },
      title: {
        text: "Total Fund Under Management (M$)",
        style: {
          fontSize: "16px",
          color: "#1b97b3",
        },
      },
    },
    {
      seriesName: "Avg Total Profit (%)",
      opposite: true,
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: "#f7941d",
      },
      labels: {
        style: {
          colors: "#f7941d",
        },
      },
      title: {
        text: "Avg. Total Profit (%)",
        style: {
          fontSize: "16px",
          color: "#f7941d",
        },
      },
    },
  ],
  tooltip: {
    fixed: {
      enabled: true,
      position: "topLeft",
      offsetY: 30,
      offsetX: 60,
    },
  },
  legend: {
    horizontalAlign: "right",
    offsetX: 40,
    position: "top",
    itemMargin: {
      horizontal: 20,
      vertical: 10,
    },
  },
};

export default function BarChart() {
  const [loading, setLoading] = useState(false);
  const [barChartSeries, setBarChartSeries] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading((x) => true);
      const plans = await getPlans(); // Replace with your API endpoint
      setLoading((x) => false);
      if (plans) {
        const transformedData = plans.map((item) => ({
          fund: parseFloat(item.fund),
          total_fund_under_managment: parseFloat(
            item.total_fund_under_managment
          ),
          avg_total_profit: parseFloat(item.avg_total_profit),
        }));
        const series = [
          {
            name: "Win-Win Fund",
            type: "column",
            color: "#7635b3",
            data: transformedData.map((item) => item.fund),
          },
          {
            name: "Total Fund Under Management",
            type: "column",
            color: "#1b97b3",
            data: transformedData.map(
              (item) => item.total_fund_under_managment
            ),
          },
          {
            name: "Avg Total Profit",
            type: "line",
            color: "#f7941d",
            data: transformedData.map((item) => item.avg_total_profit),
          },
        ];

        setBarChartSeries(series);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="hidden md:block mt-14 relative z-10">
        <div className="flex items-center gap-x-3 ">
          <img src="../yellowStar.svg" className="w-10 mb-2" alt="" />

          <span className="text-white text-lg md:text-4xl font-bold capitalize">
            plans summary chart
          </span>
        </div>
        <div className="w-full mx-auto mb-10 mt-3">
          {loading ? (
            <div className="h-[700px] bg-[--indigo-purple-dark] rounded-[19px] flex items-center justify-center">
              <Loading />
            </div>
          ) : (
            <Chart
              options={barChartOptions}
              series={barChartSeries}
              type="line"
              height={700}
            />
          )}
        </div>
      </div>
    </>
  );
}
