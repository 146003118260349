import React from "react";

export function InputsContainer({ children, logoColor }) {
  return (
    <div
      className={`w-full h-full flex flex-col items-center  max-w-md bg-[#857f974d] lg:bg-[#454253] light:!bg-transparent light:shadow-[0_0_20px_-14px] shadow-neutral-400 backdrop-blur-[8px] lg:backdrop-blur-0  relative z-10 px-12 overflow-auto scrollWidth mx-auto md:mx-0`}
    >
      <div className="w-full pb-10  ">
        {
          <svg
            className={`transition-all w-20 mx-auto ${
              logoColor === "investor" ? "fill-[#f8cc15]" : "fill-[#4743e0]"
            }`}
            width="123"
            height="123"
            viewBox="0 0 123 123"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M61.0491 0L0 61.1376L61.0491 122.275L122.098 61.1376L61.0491 0ZM60.988 11.383C62.5768 17.6253 67.4656 22.5212 73.699 24.1123C67.4656 25.7035 62.5768 30.5994 60.988 36.8418C59.4603 30.5994 54.5715 25.7035 48.3382 24.1123C54.5715 22.5212 59.4603 17.6864 60.988 11.383ZM61.0491 110.035L24.4441 73.3774L24.2608 73.1937C17.4775 66.2171 17.5386 55.0789 24.4441 48.2246L42.4106 30.2323L54.9991 42.8391L36.6661 61.1987L73.2711 97.8569L61.0491 110.035ZM79.3211 91.6759L67.0991 79.4361L85.4321 61.0765L79.3211 54.9566L60.988 73.3162L48.766 61.0765L79.26 30.5382L90.8708 42.1661L91.4821 42.778L97.5931 48.8979C104.315 55.6296 104.315 66.5842 97.5931 73.3774L79.3211 91.6759Z" />
          </svg>
        }
        <p className="text-3xl text-white text-center font-bold ">
          Golden Fund
        </p>
        <hr className="border-dashed border-[--primary-text] opacity-50 border-t-2 mt-3 mb-10" />
        {children}
      </div>
    </div>
  );
}
