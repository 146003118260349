import ReactPaginate from "react-paginate";
const style =
  "join-item text-white bg-transparent hover:bg-[var(--indigo-purple-hover)] border !border-[var(--indigo-purple-hover)] py-3 text-center";
const Pagination = ({ handleOnClick, data, optClass }) => {
  const { last_page, current_page } = data;
  return (
    <div className="w-full flex items-center justify-center">
      <ReactPaginate
        className={`w-full max-w-[500px] items-center join [&_*]:text-xs sm:[&_*]:text-sm ${optClass}`}
        activeLinkClassName="!bg-[var(--indigo-purple)]"
        pageClassName="grow inline-flex"
        previousClassName="grow inline-flex"
        nextClassName="grow inline-flex"
        pageLinkClassName={`${style} w-full`}
        previousLinkClassName={`${style} w-full !bg-[var(--indigo-purple)] hover:!bg-[var(--indigo-purple-hover)]`}
        nextLinkClassName={`${style} w-full !bg-[var(--indigo-purple)] hover:!bg-[var(--indigo-purple-hover)]`}
        breakClassName="grow inline-flex"
        breakLinkClassName={`${style} grow`}
        forcePage={current_page - 1}
        breakLabel="..."
        nextLabel="Next"
        onPageChange={(event) => handleOnClick(event)}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        pageCount={last_page}
        previousLabel="Previous"
        renderOnZeroPageCount={null}
      />
    </div>
  );
};

export default Pagination;
