import RequestDetailsForm from "../../../../components/AdminShared/RequestDetailsForm";
import RequestDetailsButton, {
  RequestDetailsButtonLayout,
} from "../../../../components/AdminShared/RequestsDetailsButton";
import RequestDetailsInput from "../../../../components/AdminShared/RequestsDetailsInput";
import TableBackground from "../../../../components/AdminShared/TableBackground";
import PageStatus from "../../../../components/PageStatus/PageStatus";
import ErrorMessage from "../../../../components/shared/ErrorMessage";
import Loading from "../../../../components/shared/Loading";
import FilterSelect from "../../../../components/AdminShared/FilterSelect";
import { useAddPayment } from "../../../../hooks/Admin/User/useAddPayment";

const AddPayment = () => {
  const {
    loading,
    submitLoading,
    error,
    navigate,
    userData,
    selectedTransactions,
    handleFilterChange,
    handleSubmit,
  } = useAddPayment();

  return (
    <>
      <PageStatus
        category={"Users"}
        currentPage={"Add Payment"}
        logoStarColor="#F8CC15"
      />
      <TableBackground>
        {loading ? (
          <div className="w-full text-center">
            <Loading />
          </div>
        ) : error ? (
          <ErrorMessage text={error}>
            <button
              className="-mb-1 border-0 btn btn-sm !text-white bg-[var(--indigo-purple)] hover:bg-[var(--indigo-purple-hover)] "
              onClick={() => navigate("/dashboard/admin/users")}
            >
              Back
            </button>
          </ErrorMessage>
        ) : (
          <>
            <RequestDetailsForm>
              <RequestDetailsInput
                title="Email"
                value={userData.user.email}
                disabled
              />
              <RequestDetailsInput
                title="Full Name"
                value={userData.user.first_name + " " + userData.user.last_name}
                disabled
              />
              <RequestDetailsInput
                title="Phone"
                value={userData.user.phone}
                disabled
              />
              <RequestDetailsInput
                title="Balance"
                value={userData.balance}
                disabled
              />

              <FilterSelect
                title="Wallet Type"
                defaultOption="Select wallet type"
                defaultDisable
                name="wallet_type_id"
                value={selectedTransactions.wallet_type_id}
                handleChange={handleFilterChange}
                options={userData.wallet_type_names}
              />

              <FilterSelect
                title="Transition Type"
                defaultOption="Select transaction type"
                defaultDisable
                name="transition_type_id"
                value={selectedTransactions.transition_type_id}
                handleChange={handleFilterChange}
                options={userData.transaction_type_names}
              />

              <FilterSelect
                title="Direction"
                defaultOption="Tansaction direction"
                defaultDisable
                name="direction"
                value={selectedTransactions.direction}
                handleChange={handleFilterChange}
                options={{ withdraw: "Debit", deposit: "Credit" }}
              />

              <RequestDetailsInput
                title="Amount"
                name="amount"
                type="number"
                value={selectedTransactions.amount}
                onChange={handleFilterChange}
              />
            </RequestDetailsForm>

            <RequestDetailsButtonLayout>
              <RequestDetailsButton
                onclick={handleSubmit}
                title="Save"
                optClass="!btn-success"
                disabled={submitLoading}
              >
                {submitLoading && (
                  <span className="loading loading-dots loading-sm"></span>
                )}
              </RequestDetailsButton>
              <RequestDetailsButton
                onclick={() => navigate("/dashboard/admin/users")}
                title="Cancel"
                optClass="btn-error"
              />
            </RequestDetailsButtonLayout>
          </>
        )}
      </TableBackground>
    </>
  );
};

export default AddPayment;
