import React from "react";
import Table from "../../../components/AdminShared/Table";
const TopTraderIndex = ({ traders, setType, setFormData }) => {
  const handleUpdate = (trader) => {
    setFormData({
      id: trader.id,
      name: trader.name,
      rank: trader.rank,
      gf_factor: trader.gf_factor,
      nation: trader.nation,
    });
    setType("update");
  };

  return (
    <div>
      <Table theads={["Id", "Name", "Rank", "Gf factor", "Nation", "Actions"]}>
        {traders
          .sort((a, b) => a.rank - b.rank)
          .map((trader) => (
            <tr key={trader.id}>
              <td>{trader.id}</td>
              <td>
                <p>{trader.name}</p>
              </td>
              <td>
                <p>{trader.rank}</p>
              </td>
              <td>
                <p>{trader.gf_factor}</p>
              </td>
              <td>
                <p>{trader.nation}</p>
              </td>
              <th>
                <button
                  onClick={() => handleUpdate(trader)}
                  className="btn btn-sm btn-outline btn-primary"
                >
                  update
                </button>
              </th>
            </tr>
          ))}
      </Table>
    </div>
  );
};

export default TopTraderIndex;
