import React, { useContext } from "react";
import { StateProvider } from "./StatesContextProvider";

export function TicketFiltersAssign({ text, setTicketType }) {
  const { activeTab, setActiveTab } = useContext(StateProvider);
  const handleClick = () => {
    setActiveTab(text);
    const value = text === "unassigned" ? 1 : 0;
    setTicketType(value);
  };
  return (
    <button
      onClick={handleClick}
      className={`h-10 flex items-center gap-x-2  border-b-2 text-[--primary-text] font-semibold px-1 transition-opacity ${
        activeTab === text
          ? "border-[--indigo-purple] opacity-100"
          : "border-transparent opacity-40"
      }`}
    >
      <span>{text}</span>
      {/* {number > 0 && (
        <div className="badge badge-sm badge-error text-white pt-0.5">
          {number}
        </div>
      )} */}
    </button>
  );
}
