import React, { useState } from "react";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { MdOutlineCopyAll } from "react-icons/md";
import { formatComma } from "../../utils/formatNumber";

export function CollapseContentRow({ title, value, balance = false }) {
  return (
    <div className="flex items-center justify-between my-2">
      <p className="text-[--primary-text]">{title} </p>
      <p className="text-neutral-200 light:text-neutral-800 font-normal">
        {balance ? `$${formatComma(value)}` : value}
      </p>
    </div>
  );
}

export function CollapseContentRowInput({ title, value }) {
  const [show, setShow] = useState(false);
  function handleCopy() {
    navigator.clipboard.writeText(value);
  }
  return (
    <div className="flex items-center justify-between my-2">
      <p className="text-[--primary-text] ">{title} </p>
      <div className="flex items-center gap-x-2 bg-[#07030A] light:bg-white rounded-lg px-2 text-white">
        <input
          type={show ? "text" : "password"}
          value={value}
          className=" max-w-[120px] outline-0 bg-transparent"
        />
        <label className="swap">
          <input type="checkbox" />
          <FaEye className="swap-on" onClick={() => setShow((prev) => !prev)} />
          <FaEyeSlash
            className="swap-off"
            onClick={() => setShow((prev) => !prev)}
          />
        </label>
        <div className="tooltip" data-tip="copy">
          <MdOutlineCopyAll
            size={20}
            className={`cursor-pointer transition-colors `}
            onClick={handleCopy}
          />
        </div>
      </div>
    </div>
  );
}

export function CollapseContent({ title, children }) {
  return (
    <div className="capitalize font-semibold bg-[#190a36] border-[var(--indigo-purple-hover)] shadow-[0_0_3px_0] shadow-[--indigo-purple] rounded-xl p-5 light:bg-neutral-100/70 light:shadow-[0_0_15px_-8px] light:shadow-neutral-400 backdrop-blur-sm">
      <p className="text-amber-400 light:text-indigo-500 text-center">
        {title}
      </p>
      {children}
    </div>
  );
}
