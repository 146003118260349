import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import { CertificateLayout } from "./CertificateLayout";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Link } from "react-router-dom";
const url = process.env.REACT_APP_API_BASE_URL + "/";

export function CertificateGrid({ data }) {
  return (
    <>
      <div className="flex items-center gap-2 mb-5 mt-16  ">
        <span className="badge badge-xs bg-white border-transparent mb-1.5"></span>
        <p className="text-white text-2xl font-bold">Certificate of Profits</p>
      </div>
      {data[0] ? (
        <Swiper
          slidesPerView={1}
          breakpoints={{
            500: {
              slidesPerView: 2,
            },
            992: {
              slidesPerView: 3,
            },
          }}
          spaceBetween={30}
          pagination={{
            clickable: true,
            el: ".swiper-custom-pagination-grid",
          }}
          navigation={true}
          allowTouchMove={false}
          modules={[Pagination, Navigation]}
          className="mySwiper [&_.swiper-button-prev]:text-white [&_.swiper-button-next]:text-white"
        >
          {data.map((item, i) => (
            <SwiperSlide key={i}>
              <CertificateLayout
                imgUrl={url + item.src}
                text={"Profit Certificate"}
                type={1}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <p className="text-neutral-300 light:text-neutral-800">
          You don't have a certificate!{" "}
          <Link
            className="text-indigo-500 light:text-[--indigo-purple-hover] hover:underline"
            to="/dashboard/new-challenge"
          >
            try to get one!
          </Link>
        </p>
      )}
      <div className="swiper-custom-pagination-grid w-full h-auto flex items-center justify-center gap-1.5 mt-5 [&_.swiper-pagination-bullet]:bg-[--primary-text] " />
      {/* <div className="w-full grid grid-cols-3 gap-5">
        {data.map((item) => (
          <CertificateLayout
            imgUrl={url + item.src}
            text={"Profit Certificate"}
            type={1}
          />
        ))}
      </div> */}
    </>
  );
}
