import { useEffect, useState } from "react";
import { usePost } from "../../API/usePost";
import { useFetch } from "../../API/useFetch";
import { useNavigate, useParams } from "react-router-dom";

export function useAddPayment() {
  const [userData, setUserData] = useState(null);
  const [selectedTransactions, setSelectedTransactions] = useState({
    wallet_type_id: "",
    transition_type_id: "",
    amount: "",
    direction: "",
  });

  const { error, loading, fetchData } = useFetch();
  const { loading: submitLoading, handlePostData } = usePost(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const getUserData = async () => {
    const res = await fetchData(
      `finance/wallets/transactions/admin/add-payment/${id}`
    );

    if (!res) return;
    setUserData(res);
  };

  const handleSubmit = () => {
    const postData = {
      user_id: id,
      ...selectedTransactions,
      direction: selectedTransactions.direction === "withdraw" ? false : true,
    };
    const _ = handlePostData(
      "finance/wallets/transactions/admin/add-payment",
      postData,
      "Successfully completed."
    );

    navigate("/dashboard/admin/users");
  };

  const handleFilterChange = (e) => {
    setSelectedTransactions((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    getUserData();
  }, []);

  return {
    loading,
    submitLoading,
    error,
    navigate,
    userData,
    selectedTransactions,
    handleFilterChange,
    handleSubmit,
  };
}
