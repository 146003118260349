// rules.js
import React from "react";

const Rules = {
  investor: () => (
    <>
      <div className="!text-[#fff] text-start">
        <div className="simple-des">
          <h2>Terms and Conditions for Investment Partnership</h2>
          <p>
            Welcome to our investment platform. By proceeding, you acknowledge
            and agree to the following Terms and Conditions which govern your
            participation in our investment partnership.
          </p>
        </div>
        <div className="simple-lower">
          <h3>1. Definitions</h3>
          <p>
            1.1 <span>Person:</span> Refers to any individual or entity, whether
            natural or legal, unless specified otherwise.
          </p>
          <p>
            1.2 <span>Third Party:</span> Any individual or legal entity other
            than the parties to this agreement.
          </p>
          <p>
            1.3 <span>Singular and Plural:</span> Words used in singular or
            plural forms include both singular and plural meanings.
          </p>
          <p>
            1.4 <span>Date:</span> Refers to the Gregorian calendar. Unless
            explicitly mentioned, a year means 12 months and 365 days, a month
            comprises 30 days, and a day comprises 24 hours. Working days
            exclude holidays, whereas calendar days include holidays.
          </p>
          <p>
            1.5 <span>Titles of the Contract:</span> Titles used in this
            contract are for reference purposes only. In case of ambiguity,
            discrepancy, or conflict, the text of the articles shall prevail.
          </p>
        </div>
        <div className="simple-lower">
          <h3>2. Subject of the Agreement</h3>
          <p>
            The subject of the agreement is the partnership in the fields of
            real estate, mining, forex, and startups within the financial
            sectors.
          </p>
        </div>
        <div className="simple-lower">
          <h3>3. Main Office</h3>
          <p>
            The main office of this partnership is located in London, United
            Kingdom.
          </p>
        </div>
        <div className="simple-lower">
          <h3>4. Duration of the Partnership</h3>
          <p>4.1 The duration of this partnership is for one (1) year.</p>
          <p>
            4.2 Renewal negotiations or continuation of activities without a
            signed agreement do not constitute an extension.
          </p>
          <p>
            4.3 Termination of the contract duration does not affect commitments
            regarding joint ownership, confidentiality, and document return.
          </p>
        </div>
        <div className="simple-lower">
          <h3>5. Capital</h3>
          <p>5.1 The capital of the partnership consists of:</p>
          <ul>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <li>
              <span>Golden Fx LTD's Contribution:</span> Technical and
              engineering services, and investments in real estate, mining, and
              forex.
            </li>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <li>
              <span>Investor's Contribution:</span> An agreed amount in cash.
            </li>
          </ul>
          <p>
            5.2 The Investor must submit a written request to withdraw any part
            of their contribution before the completion of the one-year period.
          </p>
          <p>
            5.3 In case of a capital increase, the previous proportions will be
            maintained.
          </p>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <p>
            5.4 The current capital will be managed by Golden Fx LTD as the
            executive manager. Golden Fx LTD, as trustee and agent, commits to
            using the entrusted capital towards the contract's subject, with
            liability for any loss.
          </p>
        </div>
        <div className="simple-lower">
          <h3>6. Profits and Losses</h3>
          <p>
            6.1 The profits and losses resulting from this partnership will be
            paid to the Investor as an advance payment of two and a half (2.5)
            percent per month, amounting to a total of 30 percent of the
            principal capital. At the end of the partnership period, Golden Fx
            LTD will calculate and settle the final profits and losses.
          </p>
          <p>
            6.2 Golden Fx LTD guarantees and undertakes to return the principal
            capital of the Investor and the minimum profit until the principal
            and related profit are refunded.
          </p>
          <p>
            6.3 If the Investor withdraws all or part of their capital before
            the due date, the payment of profits will be made in full up to the
            withdrawal date.
          </p>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <p>
            6.4 If at the end of the period, the Investor's partnership profit
            is less than the 30 percent advance payment received during the
            period, Golden Fx LTD will cover the difference from their own
            funds.
          </p>
        </div>
        <div className="simple-lower">
          <h3>7. Executive Management</h3>
          <p>
            7.1 The executive management of this contract will be the
            responsibility of Golden Fx LTD.
          </p>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <p>
            7.2 Golden Fx LTD's management services will be provided free of
            charge, and no costs will be considered for this service.
          </p>
        </div>
        <div className="simple-lower">
          <h3>8. Duties of the Executive Manager</h3>
          <p>
            Golden Fx LTD is responsible for directing, executing, and
            supervising the proper execution of the contract, including but not
            limited to the following:
          </p>
          <ul>
            <li>
              Continuous and permanent management regarding this contract until
              the end of the term.
            </li>
            <li>
              Marketing and negotiating with clients seeking services covered by
              the contract.
            </li>
            <li>
              Covering direct and indirect costs arising from the execution of
              the contract, such as taxes and insurance.
            </li>
            <li>
              Safeguarding the assets under control related to this contract,
              with Golden Fx LTD being liable for such protection.
            </li>
            <li>
              Closing the partnership accounts at the end of each Gregorian year
              and the end of this contract, and drafting and signing the annual
              balance sheet and profit and loss statements.
            </li>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <li>
              Paying/transferring the annual profit share to the Investor's
              designated account at the end of each Gregorian month.
            </li>
            <li>
              Supervising performance and ensuring the proper execution of
              ongoing actions.
            </li>
          </ul>
        </div>
        <div className="simple-lower">
          <h3>9. Other Rights and Obligations of the Parties</h3>
          <p>
            9.1 Golden Fx LTD is responsible for providing experienced personnel
            and covering their associated costs, including current and accrued
            wages, insurance, transportation, etc., necessary for the execution
            of the contract.
          </p>
          <p>
            9.2 The Investor does not have the right to transfer this contract
            in whole or in part, whether as representation, agency, assignment
            of rights, etc., without the consent of Golden Fx LTD.
          </p>
          <p>
            9.3 The parties confirm that they are fully informed about the
            subject and details of this partnership, and have reviewed and
            identified the scope of the contract and the defined obligations, as
            well as all risks involved in executing the project. They possess
            the technical knowledge and other requirements necessary to fulfill
            their obligations or can obtain them, and there are no unknown or
            ambiguous matters they can later claim ignorance about.
          </p>
        </div>
        <div className="simple-lower">
          <h3>10. Termination of the Contract</h3>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <p>
            10.1 The Investor may terminate the contract by providing written
            notice to Golden Fx LTD, subject to Golden Fx LTD's agreement.
          </p>
          <p>10.2 The parties may mutually agree to rescind this contract.</p>
          <p>
            10.3 If the contract is terminated by the Investor, their principal
            capital will be refunded within a maximum of 30 business days.
          </p>
          <p>
            10.4 Upon rescission or termination by the Investor, Golden Fx LTD
            will calculate and settle mutual debts and claims.
          </p>
        </div>
        <div className="simple-lower">
          <h3>11. Force Majeure</h3>
          <p>
            11.1 If the performance of any obligations under this contract by
            either party is hindered or delayed due to unforeseen events beyond
            the reasonable control of the parties, such as war, flood,
            earthquake, economic crises, etc., the affected party will be
            excused from performing those obligations until the effect of the
            event ceases.
          </p>
          <p>
            11.2 If the force majeure event lasts more than three months, the
            parties will mutually decide whether to continue or rescind the
            contract.
          </p>
        </div>
        <div className="simple-lower">
          <h3>12. Confidentiality</h3>
          <p>
            12.1 The parties, their representatives, experts, and all related
            personnel involved in the contract must treat all information and
            documents obtained or known directly or indirectly as confidential.
            They must not disclose any part of such information to any person
            without legal reasons or prior written consent from the other party
            during the contract and after the contractual relationship ends. Any
            breach will result in the responsible party being liable for both
            criminal and material, moral, and reputational damages. The parties
            commit to maintaining confidentiality for all technical,
            operational, and organizational plans and projects, whether related
            or unrelated to the contract, during and after their cooperation.
          </p>
          <p>
            12.2 Upon discovering any unauthorized use or disclosure of
            confidential information, the parties must immediately inform each
            other and cooperate reasonably to recover ownership of the
            information and prevent future unauthorized use.
          </p>
          <p>
            12.3 Either party may disclose confidential information as required
            by judicial authorities, provided they inform the other party in
            advance in writing and obtain their agreement.
          </p>
        </div>
        <div className="simple-lower">
          <h3>13. Dispute Resolution</h3>
          <p>
            13.1 All rights, obligations, and financial and legal relationships
            between the parties are governed by the laws and regulations of the
            United Kingdom.
          </p>
          <p>
            13.2 In case of disputes regarding the execution or interpretation
            of this contract, the matter will first be resolved through
            negotiation.
          </p>
          <p>
            13.3 The decision of the mutually agreed arbitrator is final,
            binding, and enforceable.
          </p>
          <p>13.4 The cost of arbitration will be borne by the losing party.</p>
          <p>
            13.5 The arbitration award will be delivered to the parties by
            express mail.
          </p>
        </div>
        <div className="simple-lower">
          <h3>14. Copies of the Contract</h3>
          <p>
            This contract is drafted in fourteen articles. It was prepared,
            signed, and exchanged, and all copies are equally valid.
          </p>
        </div>
        <div className="simple-des">
          <p>
            By continuing to invest through our platform, you, the Investor,
            acknowledge that you have read, understood, and agree to these Terms
            and Conditions.
          </p>
        </div>
      </div>
    </>
  ),
  trader: () => (
    <>
      <div className="text-[#fff] text-start">
        <div className="simple-des">
          <h2>Proprietary Trading Terms and Conditions</h2>
          <p>
            Welcome to Golden Fund, a premier forex proprietary trading firm. By
            accessing our services, you agree to adhere to the following terms
            and conditions:
          </p>
        </div>
        <div className="simple-lower">
          <h3>1. Access to Capital:</h3>
          <p>
            Golden Fund provides traders with access to substantial capital for
            trading activities. This capital empowers traders to take on larger
            positions, potentially amplifying profits. Traders must adhere to
            risk limits set by Golden Fund, which may result in drawdowns under
            certain circumstances.
          </p>

          <h3>2. No Need for Personal Capital:</h3>
          <p>
            Traders are not required to utilize their capital for trading with
            Golden Fund. This eliminates personal financial risk, enabling
            traders to engage in trading without risking their funds.
          </p>

          <h3>3. Reduced Trading Costs:</h3>
          <p>
            Golden Fund negotiates lower trading fees, resulting in reduced
            costs for traders. This advantage is attributed to the firm's
            significant trading volume, leading to enhanced profit margins for
            traders.
          </p>

          <h3>4. Professional Trading Platforms:</h3>
          <p>
            Traders are granted access to professional trading platforms
            tailored for high-frequency trading and advanced order types. This
            facilitates efficient trade execution and enhances profit potential.
          </p>

          <h3>5. Support and Mentorship:</h3>
          <p>
            Golden Fund offers comprehensive training, coaching, and support
            from experienced traders. This guidance assists traders in
            developing effective trading strategies and skills, potentially
            leading to more profitable trades. Additionally, successful traders
            may be eligible for performance bonuses or profit-sharing
            agreements.
          </p>

          <h3>6. Strict Risk Management Conditions:</h3>
          <p>
            Golden Fund implements stringent risk management protocols to help
            traders maintain discipline and manage risk effectively. This is
            particularly beneficial for novice traders or those susceptible to
            emotional trading.
          </p>

          <h3>7. Trader Profits:</h3>
          <p>
            The majority of profits generated by traders belong to the trader,
            while Golden Fund bears the associated risks. This financial
            incentive encourages traders to perform well and achieve their
            trading objectives.
          </p>

          <h3>8. Clear Rules for Account Verification:</h3>
          <p>
            Golden Fund maintains clear and straightforward procedures for
            account verification and setup. This streamlines the administrative
            process and ensures traders can promptly commence trading
            activities.
          </p>

          <h3>9. Freedom after Verification:</h3>
          <p>
            Upon successful verification, traders enjoy the freedom to trade as
            they see fit, without the obligation to consistently generate
            profits or meet specific targets. This flexibility allows traders to
            focus on refining their trading strategies.
          </p>

          <h3>10. Incentives for Consistent Traders:</h3>
          <p>
            Golden Fund may offer enticing terms and conditions for traders who
            demonstrate consistent profitability over time. Such incentives may
            include performance bonuses, profit-sharing agreements, or other
            rewards.
          </p>

          <h3>11. Remote Trading:</h3>
          <p>
            Traders have the flexibility to trade from any location worldwide,
            whether from home or while traveling. This provides a significant
            advantage over traditional trading firms that impose location or
            time constraints on traders.
          </p>

          <h3>12. Trading Strategies:</h3>
          <p>
            Traders are permitted to utilize their trading strategies or
            automated trading systems (AOS) while trading with Golden Fund. This
            autonomy enables traders to leverage their expertise and experience
            for profit generation, unrestricted by predefined strategies or
            systems.
          </p>
          <div className="simple-des">
            <p>
              By engaging with Golden Fund, you acknowledge and accept these
              terms and conditions. Golden Fund reserves the right to amend
              these terms at its discretion. For any inquiries or clarifications
              regarding these terms, please contact our customer support team.
              Happy trading!
            </p>
          </div>
        </div>
      </div>
    </>
  ),
  // Add all other rules here...
};

export default Rules;
