import React, { useEffect, useRef, useState } from "react";
import { IoIosAttach } from "react-icons/io";
const defaultInputs = {
  message: "",
  isPrivate: false,
  files: [],
};

export function ChatForm({
  addNewMessage,
  addNewFile,
  setNewMessage,
  handleClose,
}) {
  const fileInputRef = useRef(null);
  const [inputs, setInputs] = useState(defaultInputs);
  const [fileUpload, setFileUpload] = useState(null);
  const send = useRef();

  useEffect(() => {
    document.addEventListener("keydown", function (event) {
      if (event.ctrlKey && event.key === "Enter") {
        send.current.click();
      }
    });
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFileUpload(file.name);
  };

  const handleButtonClick = () => {
    console.log(fileInputRef);
    fileInputRef.current.click();
  };

  function handleSubmit() {
    setNewMessage((prev) => !prev);
    if (inputs.message) {
      addNewMessage(inputs);
    }
    if (fileInputRef.current.files[0]) {
      addNewFile(fileInputRef.current.files[0]);
    }
    fileInputRef.current.value = "";
    setNewMessage((prev) => !prev);
    setFileUpload((prev) => null);
    setInputs(defaultInputs);
  }
  return (
    <div className="w-full border border-[--border-light] light:bg-yellow-600/5 px-2 md:px-10 py-5 rounded-lg">
      <form onSubmit={(e) => e.preventDefault()}>
        {fileUpload ? (
          <p className="rounded-md flex items-center gap-1 bg-slate-800 text-neutral-100 max-w-fit px-2 py-1">
            {fileUpload}
          </p>
        ) : (
          <textarea
            value={inputs.message}
            onChange={(e) =>
              setInputs((prev) => ({ ...prev, message: e.target.value }))
            }
            onInvalid={(e) => e.preventDefault()}
            required={true}
            className="w-full bg-transparent text-white outline-0 border-0"
            placeholder="write your message here"
            rows="2"
          />
        )}
        <div className="flex items-center justify-end">
          <div className="flex flex-wrap-reverse items-center gap-2">
            <button className="btn btn-success" onClick={handleClose}>
              close ticket
            </button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept="*"
              hidden
              onChange={handleImageChange}
            />
            <button
              className="btn light:border-neutral-400"
              onClick={handleButtonClick}
            >
              <IoIosAttach size={20} />
              Attach
            </button>
            <button
              ref={send}
              className="btn bg-[var(--indigo-purple)] hover:bg-[var(--indigo-purple-hover)] text-white border-[--border-light] hover:border-indigo-500 light:hover:border-orange-500"
              onClick={handleSubmit}
            >
              send
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
