import React from "react";
import { RankingTable } from "./RankingTable";
import { RankingHeader } from "./RankingHeader";
import { RankingTopUserLayout } from "./RankingTopUserLayout";

export function RankingTopUsers({ data, headerText, type }) {
  const tops = data.filter((item) => item.rank >= 1 && item.rank <= 3);
  const other = data.filter((item) => item.rank > 3);

  return (
    <div className="rounded-[50px] bg-[#11092E] light:bg-transparent light:shadow-[0_0_20px_-10px] light:shadow-neutral-400 light:backdrop-blur-[3px] pb-10">
      <RankingHeader headerText={headerText} />
      <RankingTopUserLayout data={tops} type={type} />
      <RankingTable data={other} type={type} />
    </div>
  );
}
