import React from "react";

export function ActivePlansRow({ plans, children }) {
  return (
    <div class="collapse collapse-open rounded-none hover:!bg-indigo-900/50 light:hover:!bg-yellow-500/20 light:bg-yellow-500/10">
      <input type="radio" name="my-accordion-2" />
      <div class="collapse-title text-xl font-medium px-0 ">{plans}</div>
      <div class="collapse-content grid grid-cols-3 gap-x-3">{children}</div>
    </div>
  );
}
