import { PaymentElement } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(true);

  useEffect(() => {
    if (elements) {
      const element = elements.getElement("payment");
      element.on("ready", () => {
        setIsProcessing(false);
      });
    }
  }, [elements]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_API_BASE_URL}/api/deposit-submit`,
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsProcessing(false);
  };

  return (
    <form id="payment-form" className="max-w-[292px]" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" className="dark-theme" />
      <button
        className="btn mt-5 !bg-[var(--indigo-purple)] !border-[--border-light] hover:bg-[var(--indigo-purple-hover)]  hover:border-transparent !text-white disabled:opacity-50 "
        disabled={isProcessing || !stripe || !elements}
        id="submit"
      >
        <span id="button-text">{isProcessing ? "Loading ... " : "Pay"}</span>
      </button>
      {message && (
        <div id="payment-message" className="text-[#fe87a1] mt-2">
          {message}
        </div>
      )}
    </form>
  );
}
