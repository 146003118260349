import { useState } from "react";
import { SubmitButton } from "../../components/shared/SubmitButton";
import { PaymentInput } from "./PaymentInput";

import { PaymentMethod } from "./PaymentMethod";
export function PaymentLayout({ withdrawData, handleSetWithdraw }) {
  const [paymentAddress, setPaymentAddress] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    handleSetWithdraw({
      trc20_wallet_address: paymentAddress,
      step: 3,
    });
  }
  return (
    <form
      onSubmit={handleSubmit}
      className="w-full max-w-lg grid grid-cols-1 gap-2 "
    >
      <PaymentMethod paymentMethod={withdrawData.type} />
      <PaymentInput func={setPaymentAddress} value={paymentAddress} />
      <SubmitButton />
    </form>
  );
}
