import React from "react";

import RequestDetailsInput from "../../../components/AdminShared/RequestsDetailsInput";
import RequestDetailsForm from "../../../components/AdminShared/RequestDetailsForm";
import RequestDetailsButton, {
  RequestDetailsButtonLayout,
} from "../../../components/AdminShared/RequestsDetailsButton";

const inputStyle = "!input-md !text-xl text-black";

const PlanInfoUpdate = ({ formData, setFormData, handleUpdate }) => {
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <div className="pb-10">
      <RequestDetailsForm>
        <RequestDetailsInput
          title="total"
          name="total"
          titleClass="text-xl"
          optClass={inputStyle}
          value={formData.total}
          onChange={handleInputChange}
        />
        <RequestDetailsInput
          title="win win"
          name="win_win"
          titleClass="text-xl"
          optClass={inputStyle}
          value={formData.win_win}
          onChange={handleInputChange}
        />
        <RequestDetailsInput
          title="golden"
          name="golden"
          titleClass="text-xl"
          optClass={inputStyle}
          value={formData.golden}
          onChange={handleInputChange}
        />
        <RequestDetailsInput
          title="standard"
          name="standard"
          titleClass="text-xl"
          optClass={inputStyle}
          value={formData.standard}
          onChange={handleInputChange}
        />
      </RequestDetailsForm>
      <RequestDetailsButtonLayout>
        <RequestDetailsButton
          title="Update"
          onclick={handleUpdate}
          optClass="btn-md btn-primary"
        />
      </RequestDetailsButtonLayout>
    </div>
  );
};

export default PlanInfoUpdate;
