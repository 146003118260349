export function AuthSubmitBtn({ bgColor, title, type, optClass, ...props }) {
  return (
    <button
      type={type}
      className={`w-full btn border-0 rounded-none mb-2 ${optClass}   ${
        bgColor === "investor"
          ? "bg-[var(--invest-gold)] hover:bg-[var(--invest-gold-hover)] text-black"
          : "hover:bg-indigo-700 bg-[#4743e0] text-[#fff]"
      }`}
      {...props}
    >
      {title}
    </button>
  );
}
