export default function UserRankTable({ userRank }) {
  return (
    <div className="w-full grid grid-cols-1 items-end sm:grid-cols-3 gap-x-10 gap-y-2 rounded-lg ">
      <UserRankTableItem title="Rank">
        <span className="relative">
          {userRank.rank}
          <span className="text-sm absolute top-0 -right-5 !normal-case">
            th
          </span>
        </span>
      </UserRankTableItem>
      <UserRankTableItem
        title="Active Date Account"
        value={`${userRank.year}/${userRank.month}`}
      />
      <UserRankTableItem
        title="GF Factor"
        value={userRank.user_challenge?.golden_factor ?? "not found!"}
        last
      />
    </div>
  );
}

function UserRankTableItem({ title = "", last = false, value, children }) {
  return (
    <div className="grid grid-cols-1 text-white text-xl font-semibold text-center">
      <div className="bg-[var(--indigo-purple)] flex items-center justify-center rounded-t-lg py-2 -mb-1">
        {title}
      </div>
      <div className="bg-[var(--indigo-purple-dark)] light:bg-[#ece7c4] flex items-center justify-center to-transparent border-t border-light-border py-4 rounded-b-rounded-t-lg relative z-0">
        {!last && (
          <span className="max-sm:hidden absolute w-full h-full bg-[var(--indigo-purple-dark)] light:bg-[#ece7c4] top-0 left-1/2 -z-10"></span>
        )}
        {children}
        {value}
      </div>
    </div>
  );
}
