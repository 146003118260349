import { useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { usePost } from "../../hooks/API/usePost";
import { Link, useNavigate } from "react-router-dom";
import { postData } from "../../utils/fetchData";
import { toast } from "react-toastify";
const ConfirmPay = ({ data, setStep }) => {
  const {
    challenge,
    balance,
    balance_id,
    platform,
    broker,
    wallet,
    price,
    discountable,
  } = data;
  const [coupon, setCoupon] = useState("");
  const [discount, setDiscount] = useState(0);
  const [loading, setLodaing] = useState(false);
  const { loading: discoutnLoading, handlePostData } = usePost(false);
  const navigate = useNavigate();

  const [paymentFrom, setPaymentFrom] = useState(
    +wallet >= price ? "wallet" : "online"
  );

  async function handleCouponSubmit() {
    if (!coupon) return;
    const data = {
      token: coupon,
    };
    const res = await handlePostData("finance/discount-tokens/evaluate", data);
    if (res) {
      setDiscount(res.percentage);
    } else {
      setDiscount(0);
    }
  }

  async function handlePay() {
    setLodaing((prev) => true);
    const formData = {
      balance_id: balance_id,
      broker: broker,
      platform: platform,
      from_wallet: paymentFrom === "wallet",
      token: coupon,
    };
    const res = await postData("challenges/user-challenges/pay", formData);
    setLodaing((prev) => false);
    if (!res.data) return;
    if (res.data?.url) {
      window.location.href = res.data.url;
      toast.success(res.data.message ?? "Redirecting to payment page...");
    } else {
      navigate("/dashboard/my-challenges");
      toast.success(res.data.message ?? "Your payment was successful. ");
    }
  }
  return (
    <div>
      <div className="bg-gradient-to-br from-indigo-900 to-indigo-950 light:from-white light:to-neutral-200 light:shadow-[0_0_20px_-12px] light:shadow-neutral-300 rounded-xl p-10 max-xl:px-5">
        <button
          onClick={() => setStep((prev) => ({ ...prev, step: 1 }))}
          className="btn btn-sm btn-square gap-1 pb-1 justify-center bg-[var(--indigo-purple)] hover:bg-[var(--indigo-purple-hover)] text-white border-[--border-light] hover:border-indigo-500 light:hover:border-orange-500"
        >
          <IoArrowBack size={19} />
        </button>
        <div className="w-full max-w-6xl grid min-[1120px]:grid-cols-2 gap-10 xl:gap-32  text-white mx-auto text-lg">
          <div className="max-[1120px]:order-2">
            <h4 className="text-2xl font-semibold text-center">Payment</h4>
            <div className="w-full grid-grid-cols-1 mt-3 [&>div:not(:last-child)]:mb-2">
              <div className="border-b border-b-neutral-300/20 light:border-b-neutral-600/20 border-dashed p-2 px-3 w-full flex items-center justify-between">
                <h4>Available in wallet</h4>
                <p>${wallet}</p>
              </div>
              <div className="border-b border-b-neutral-300/20 light:border-b-neutral-600/20 border-dashed p-2 px-3 w-full flex items-center justify-between">
                <h4>Pay from</h4>
                <form
                  className="flex items-center gap-4"
                  onChange={(e) => setPaymentFrom(e.target.value)}
                >
                  <div className="form-control ">
                    <label className="label cursor-pointer p-0">
                      <span className="label-text text-white ">Wallet</span>
                      <input
                        value="wallet"
                        disabled={loading}
                        checked={paymentFrom === "wallet"}
                        type="radio"
                        name="payment-type"
                        className="radio radio-xs ml-2 border-[--primary-text] mb-1"
                      />
                    </label>
                  </div>
                  <div className="form-control ">
                    <label className="label cursor-pointer p-0">
                      <span className="label-text text-white">Online</span>
                      <input
                        disabled={loading}
                        type="radio"
                        checked={paymentFrom === "online"}
                        value="online"
                        name="payment-type"
                        className="radio radio-xs ml-2 border-[--primary-text] mb-1"
                      />
                    </label>
                  </div>
                </form>
              </div>
              <div className="border-b border-b-neutral-300/20 light:border-b-neutral-600/20 border-dashed p-2 px-3 w-full flex items-center justify-between">
                <h4>Coupon</h4>
                {!discountable ? (
                  <span className="text-neutral-400 text-sm">
                    Not available for {challenge}
                  </span>
                ) : (
                  <div className="join max-[410px]:max-w-[170px] max-w-[250px] mb-2">
                    <input
                      className="input input-sm input-bordered join-item grow w-full text-black"
                      placeholder="Coupon Code"
                      type="text"
                      value={coupon}
                      onChange={(e) => setCoupon(e.target.value)}
                    />
                    <button
                      disabled={discoutnLoading || loading}
                      onClick={handleCouponSubmit}
                      className="btn btn-sm font-medium w-[60px] join-item outline-0 border-0 text-white bg-[--indigo-purple] hover:bg-[--indigo-purple-hover] disabled:bg-neutral-300 disabled:cursor-not-allowed"
                    >
                      {discoutnLoading ? (
                        <span className="loading loading-dots loading-xs"></span>
                      ) : (
                        "Apply"
                      )}
                    </button>
                  </div>
                )}
              </div>
              <div className="border-b border-b-neutral-300/20 light:border-b-neutral-600/20 border-dashed p-2 px-3 w-full flex items-center justify-between">
                <h4>Price</h4>
                <p>${price}</p>
              </div>
              <div className="border-b border-b-neutral-300/20 light:border-b-neutral-600/20 border-dashed p-2 px-3 w-full flex items-center justify-between">
                <h4>Discount Amount</h4>
                {discoutnLoading ? (
                  <div className="w-[60px] h-[20px] skeleton bg-[var(--indigo-purple-hover)] opacity-40 rounded-none "></div>
                ) : (
                  <p className="text-error ">
                    {discount > 0 ? (
                      <>
                        <span>-${(price * (discount / 100)).toFixed(2)}</span>{" "}
                        <span>(%{discount})</span>
                      </>
                    ) : (
                      <span>$0</span>
                    )}
                  </p>
                )}
              </div>

              <div className="p-2 px-3 w-full flex items-center justify-between">
                <h4 className="font-semibold text-xl">Total</h4>
                {discoutnLoading ? (
                  <div className="w-[80px] h-[20px] skeleton bg-[var(--indigo-purple-hover)] opacity-40 rounded-none "></div>
                ) : (
                  <p className="font-bold text-xl text-indigo-500 light:text-[--indigo-purple]">
                    <span>
                      ${(price * ((100 - discount) / 100)).toFixed(2)}
                    </span>
                  </p>
                )}
              </div>
              <div className="w-full">
                {+wallet < (price * ((100 - discount) / 100)).toFixed(2) &&
                paymentFrom === "wallet" ? (
                  <label className="w-fit mx-auto ">
                    <div className="label">
                      <span className="text-lg label-text text-error ">
                        Not Enough Fund!
                      </span>
                    </div>
                    <Link
                      to="/dashboard/deposit"
                      className="w-full btn text-white hover:border-indigo-500 light:hover:border-orange-500 border-[--border-light] bg-[var(--indigo-purple)] hover:bg-[var(--indigo-purple-hover)]"
                    >
                      Deposit
                    </Link>
                  </label>
                ) : (
                  <button
                    onClick={handlePay}
                    disabled={loading}
                    className="btn btn-success disabled:bg-success disabled:opacity-50 w-full text-lg"
                  >
                    {!loading ? (
                      "Pay"
                    ) : (
                      <span className="loading loading-dots loading-xs"></span>
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>

          {/* info */}
          <div className="w-full max-[1120px]:order-1">
            <h4 className="text-2xl font-semibold text-center">Plan Info</h4>
            <div className="w-full grid-grid-cols-1 mt-3 [&>div:not(:last-child)]:mb-2">
              <div className="border-b border-b-neutral-300/20 light:border-b-neutral-600/20 border-dashed p-2 px-3 w-full flex items-center justify-between">
                <h4>Plan Type</h4>
                <p className="capitalize">{challenge}</p>
              </div>
              <div className="border-b border-b-neutral-300/20 light:border-b-neutral-600/20 border-dashed p-2 px-3 w-full flex items-center justify-between">
                <h4>Platform</h4>
                <p>{platform}</p>
              </div>
              <div className="border-b border-b-neutral-300/20 light:border-b-neutral-600/20 border-dashed p-2 px-3 w-full flex items-center justify-between">
                <h4>Balance</h4>
                <p>{balance}</p>
              </div>
              <div className=" p-2 px-3 w-full flex items-center justify-between">
                <h4>Broker</h4>
                <p>{broker}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPay;
