import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFetch } from "../hooks/API/useFetch";
import {toast} from "react-toastify";
const initialReferralBalances = {
  balance: null,
  profitShareSum: null,
  fundShareSum: null,
};

export function useReferrals() {
  const user = useSelector((state) => state.auth.user);
  const [referralBalances, setReferralBalances] = useState(
    initialReferralBalances
  );
  const [loading, setLoading] = useState(false);
  const { fetchData } = useFetch();

  const getBalances = async () => {
    setLoading((prev) => true);
    const res = await fetchData("finance/wallets/referrals/balance");
    setLoading((prev) => false);
    if (!res) return;
    setReferralBalances({
      balance: res.balance,
      profitShareSum: res.profit_share_sum,
      fundShareSum: res.fund_share_sum,
    });
  };

  const handleBalance = async () => {
    setLoading((prev) => true);
    const res = await fetchData("users/referrals/deposit");
    if(res) {
      toast.success(res.message)
      getBalances();
    }
    setLoading((prev) => false);
  };

  useEffect(() => {
    if (referralBalances.balance === null) {
      getBalances();
    }
  }, [referralBalances]);

  return { loading, user, referralBalances, handleBalance };
}
