import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../store/slices/auth";
import { toast } from "react-toastify";

export function useLogOut() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleLogOut = async () => {
    setLoading((prev) => true);
    const dispatchData = await dispatch(logout());
    setLoading((prev) => false);
    if (!dispatchData) {
      toast.error("Error! Please Try Again Later.");
    } else {
      toast.success("logout Successfully");
      localStorage.removeItem("userData");
      navigate("/");
    }
  };

  return { loading, handleLogOut };
}
