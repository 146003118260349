import RequestDetailsInput from "../../../components/AdminShared/RequestsDetailsInput";

const nations = ["UAE", "IR", "TR", "CH", "KR", "GE", "EN", "BE", "IT", "CA"];

const GoldenTopTraderUpdate = ({
  setType,
  formData,
  setFormData,
  handleUpdate,
}) => {
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <>
      <div className=" flex  items-center justify-between title text-center text-white mb-5">
        <h3 className="text-2xl text-center w-full ">
          Golden top trader update
        </h3>
        <button
          onClick={() => setType("index")}
          className="btn btn-secondary btn-outline"
        >
          back
        </button>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
        <RequestDetailsInput
          title="Rank"
          name="rank"
          optClass="!input-md"
          titleClass="text-lg"
          value={formData.rank}
          disabled
        />
        <RequestDetailsInput
          title="Name"
          name="name"
          optClass="!input-md"
          titleClass="text-lg"
          value={formData.name}
          onChange={handleInputChange}
        />
        <RequestDetailsInput
          title="Gf factor"
          name="gf_factor"
          optClass="!input-md"
          titleClass="text-lg"
          type="number"
          value={formData.gf_factor}
          onChange={handleInputChange}
        />
        <RequestDetailsInput
          title="Participation"
          name="participation"
          optClass="!input-md"
          titleClass="text-lg"
          type="number"
          value={formData.participation}
          onChange={handleInputChange}
        />
        <label className="form-control ">
          <div className="label">
            <span className="label-text text-slate-100 text-lg">Nation</span>
          </div>
          <select
            name="nation"
            value={formData.nation}
            onChange={handleInputChange}
            className="select select-md select-bordered bg-slate-300"
          >
            {nations.map((nation) => {
              return (
                <option key={nation} value={nation}>
                  {nation}
                </option>
              );
            })}
          </select>
        </label>
      </div>

      <button
        className="btn btn-outline btn-success mt-10 mb-5"
        onClick={handleUpdate}
      >
        submit
      </button>
    </>
  );
};

export default GoldenTopTraderUpdate;
