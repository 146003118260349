import { useEffect, useState } from "react";
import { getData } from "../../utils/fetchData";
import { toast } from "react-toastify";
import { usePut } from "../API/usePut";
const initialFormData = {
  id: null,
  name: null,
  rank: null,
  gf_factor: null,
  nation: null,
};
export function useTopTraders() {
  const [type, setType] = useState("index");
  const [traders, setTraders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const { handlePutData } = usePut();

  const getTraders = async () => {
    setLoading((prev) => true);

    const res = await getData("top-trader");
    if (res?.data) {
      setTraders(res.data.top_traders);
    } else {
      toast.error(res?.data.message || "Unexpected error!");
      setError((prev) => "Error fetching data!");
    }

    setLoading((prev) => false);
  };
  const handleUpdate = async () => {
    setLoading((prev) => true);
    const res = await handlePutData(
      "top-traders/" + formData.id,
      formData,
      "successfully updated!"
    );
    setLoading((prev) => false);
    if (!res) return;

    setType("index");
    getTraders();
  };

  useEffect(() => {
    getTraders();
  }, []);

  return {
    loading,
    error,
    traders,
    type,
    setType,
    setFormData,
    formData,
    handleUpdate,
  };
}
