export default function StepIndicator({
  num,
  text,
  last = false,
  currentStep,
  onClick,
}) {
  function handleClick() {
    if (currentStep > num) {
      onClick({ step: num });
    }
  }

  return (
    <li onClick={handleClick} className="relative w-full mb-6 cursor-pointer">
      <div className="flex items-center">
        <div
          className={`min-w-6 min-h-6 max-w-6 max-h-6 z-10 font-bold flex items-center justify-center  rounded-full pt-1 ${
            currentStep >= num
              ? " bg-[var(--indigo-purple)] text-white "
              : "bg-[#C0C0C0] text-white"
          }`}
        >
          <span>{num}</span>
        </div>
        {!last && <div className="flex w-full h-0.5 bg-white"></div>}
      </div>
      <div className="mt-3">
        <span className={`text-xs  text-white capitalize `}>{text}</span>
      </div>
    </li>
  );
}
