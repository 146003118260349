export default function FilterSelect({
  title = "",
  name = "",
  value,
  handleChange,
  options = null,
  defaultOption = "All",
  defaultDisable = false,
  titleClass = "",
  optClass = "",
  children,
}) {
  return (
    <label htmlFor={name} className="form-control">
      <div className="label">
        <span className={`label-text text-slate-100 capitalize ${titleClass}`}>
          {title}:
        </span>
      </div>
      <select
        id={name}
        name={name}
        value={value}
        onChange={handleChange}
        defaultValue=""
        className={`select select-sm  text-black select-bordered bg-slate-300 ${optClass}`}
      >
        <option disabled={defaultDisable} value="">
          {defaultOption}
        </option>

        {options
          ? Object.entries(options).map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))
          : children}
      </select>
    </label>
  );
}
